import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import configSetup from "../setup";

const { companyProfileName } = configSetup();

const getHours = () => {
  const hours = ref(null);
  const availableHours = [];
  let dayHours;
  let open;
  let closing;
  let workHours;
  let day;

  const schedule = {
    hours: [
      {
        day: "Sunday",
        availableHours: [],
      },
      {
        day: "Monday",
        availableHours: [],
      },
      {
        day: "Tuesday",
        availableHours: [],
      },
      {
        day: "Wednesday",
        availableHours: [],
      },
      {
        day: "Thursday",
        availableHours: [],
      },
      {
        day: "Friday",
        availableHours: [],
      },
      {
        day: "Saturday",
        availableHours: [],
      },
    ],
  };

  const hoursRef = projectFirestore
    .collection("companyProfile")
    .doc(companyProfileName)
    .collection("hours")
    .doc("openHours");

  hoursRef.get().then((doc) => {
    hours.value = doc.data();

    for (const [key, value] of Object.entries(hours.value)) {
      open = value.open;
      closing = value.close - 12;
      workHours = value.close - value.open;
      day = value.day;

      availableHours.push({
        open: value.open,
        closing: value.close - 12,
        workHours: value.close - value.open,
        day: value.day,
      });
      if (value.open > 0) {
        for (dayHours = open; dayHours < value.close; dayHours++) {
          if (value.day === "Monday") {
            if (dayHours < 12) {
              schedule.hours[1].availableHours.push({
                hour: dayHours,
                dayTime: "AM",
              });
            }
            if (dayHours > 12) {
              schedule.hours[1].availableHours.push({
                hour: dayHours - 12,
                dayTime: "PM",
              });
            }
            if (dayHours == 12) {
              schedule.hours[1].availableHours.push({
                hour: dayHours,
                dayTime: "PM",
              });
            }
          }
          if (value.day === "Tuesday") {
            if (dayHours < 12) {
              schedule.hours[2].availableHours.push({
                hour: dayHours,
                dayTime: "AM",
              });
            }
            if (dayHours > 12) {
              schedule.hours[2].availableHours.push({
                hour: dayHours - 12,
                dayTime: "PM",
              });
            }
            if (dayHours == 12) {
              schedule.hours[2].availableHours.push({
                hour: dayHours,
                dayTime: "PM",
              });
            }
          }
          if (value.day === "Wednesday") {
            if (dayHours < 12) {
              schedule.hours[3].availableHours.push({
                hour: dayHours,
                dayTime: "AM",
              });
            }
            if (dayHours > 12) {
              schedule.hours[3].availableHours.push({
                hour: dayHours - 12,
                dayTime: "PM",
              });
            }
            if (dayHours == 12) {
              schedule.hours[3].availableHours.push({
                hour: dayHours,
                dayTime: "PM",
              });
            }
          }
          if (value.day === "Thursday") {
            if (dayHours < 12) {
              schedule.hours[4].availableHours.push({
                hour: dayHours,
                dayTime: "AM",
              });
            }
            if (dayHours > 12) {
              schedule.hours[4].availableHours.push({
                hour: dayHours - 12,
                dayTime: "PM",
              });
            }
            if (dayHours == 12) {
              schedule.hours[4].availableHours.push({
                hour: dayHours,
                dayTime: "PM",
              });
            }
          }
          if (value.day === "Friday") {
            if (dayHours < 12) {
              schedule.hours[5].availableHours.push({
                hour: dayHours,
                dayTime: "AM",
              });
            }
            if (dayHours > 12) {
              schedule.hours[5].availableHours.push({
                hour: dayHours - 12,
                dayTime: "PM",
              });
            }
            if (dayHours == 12) {
              schedule.hours[5].availableHours.push({
                hour: dayHours,
                dayTime: "PM",
              });
            }
          }
          if (value.day === "Saturday") {
            if (dayHours < 12) {
              schedule.hours[6].availableHours.push({
                hour: dayHours,
                dayTime: "AM",
              });
            }
            if (dayHours > 12) {
              schedule.hours[6].availableHours.push({
                hour: dayHours - 12,
                dayTime: "PM",
              });
            }
            if (dayHours == 12) {
              schedule.hours[6].availableHours.push({
                hour: dayHours,
                dayTime: "PM",
              });
            }
          }
          if (value.day === "Sunday") {
            if (dayHours < 12) {
              schedule.hours[0].availableHours.push({
                hour: dayHours,
                dayTime: "AM",
              });
            }
            if (dayHours > 12) {
              schedule.hours[0].availableHours.push({
                hour: dayHours - 12,
                dayTime: "PM",
              });
            }
            if (dayHours == 12) {
              schedule.hours[0].availableHours.push({
                hour: dayHours,
                dayTime: "PM",
              });
            }
          }
        }
      }
    }
  });

  const icons = [
    {
      name: "5DR Hatchback",
      icon: {
        rearIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 108 108"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M104,47.09v7.17a49.08,49.08,0,0,1-.63,7.85,2.06,2.06,0,0,1-1.68,1.69l-4.39.75h-.54L14.08,66.49l-2,0C3,66,4,60.82,4,60.82a39.53,39.53,0,0,1,1.44-7.73,10.6,10.6,0,0,1,2-4A3.08,3.08,0,0,1,9,48.15a187.12,187.12,0,0,1,24.71-4.71S48.36,33.38,56.55,33.5c0,0,28.19-1.24,35,3.48,0,0,7.54,4.42,10.87,7.66C103.43,45.62,104,46.48,104,47.09Z"
            />
            <path
              class="hb5-2"
              d="M17.2,48.8s-.59,3.94-11.73,4.29a10.6,10.6,0,0,1,2-4Z"
            />
            <path
              class="hb5-2"
              d="M104,47.09v1.64c-9.74-.53-9.88-4.08-9.88-4.08h8.32C103.43,45.62,104.05,46.48,104,47.09Z"
            />
            <circle class="hb5-2" cx="86.9" cy="64.79" r="9.85" />
            <circle class="hb5-2" cx="23.78" cy="64.79" r="9.85" />
            <line class="hb5-3" x1="30.08" y1="64.79" x2="17.48" y2="64.79" />
            <line class="hb5-3" x1="23.78" y1="71.09" x2="23.78" y2="58.5" />
            <line class="hb5-3" x1="28.23" y1="60.34" x2="19.33" y2="69.25" />
            <line class="hb5-3" x1="28.23" y1="69.25" x2="19.33" y2="60.34" />
            <path
              class="hb5-2"
              d="M60.48,50.55H56.15a.75.75,0,1,1,0-1.5h4.33a.75.75,0,1,1,0,1.5Z"
            />
            <path
              class="hb5-2"
              d="M84.48,50.55H80.15a.75.75,0,0,1,0-1.5h4.33a.75.75,0,0,1,0,1.5Z"
            />
            <path
              class="hb5-4"
              d="M59.3,37.46l2.47-.09L60.92,46c-9.58.42-21.78.86-23.17.48C37.75,46.44,49.2,37,59.3,37.46Z"
            />
            <path
              class="hb5-2"
              d="M79.43,37.44l-.74,6.41L72.6,45.4s-4.44.23-10.16.49l.84-8.57C67.33,37.22,73.8,37.14,79.43,37.44Z"
            />
            <path
              class="hb5-2"
              d="M88.78,38.75s-.16,1.29-2.16,3.08l-6.38,1.62.69-5.91A33.57,33.57,0,0,1,88.78,38.75Z"
            />
            <circle class="hb5-3" cx="23.78" cy="64.79" r="6.3" />
            <circle class="hb5-3" cx="86.9" cy="64.79" r="6.3" />
            <line class="hb5-3" x1="93.2" y1="64.79" x2="80.61" y2="64.79" />
            <line class="hb5-3" x1="86.9" y1="71.09" x2="86.9" y2="58.5" />
            <line class="hb5-3" x1="91.36" y1="60.34" x2="82.45" y2="69.25" />
            <line class="hb5-3" x1="91.36" y1="69.25" x2="82.45" y2="60.34" />
          </svg>
        ),
        fullIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 108 108"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M104,47.09v7.17a49.08,49.08,0,0,1-.63,7.85,2.06,2.06,0,0,1-1.68,1.69l-4.39.75h-.54L14.08,66.49l-2,0C3,66,4,60.82,4,60.82a39.53,39.53,0,0,1,1.44-7.73,10.6,10.6,0,0,1,2-4A3.08,3.08,0,0,1,9,48.15a187.12,187.12,0,0,1,24.71-4.71S48.36,33.38,56.55,33.5c0,0,28.19-1.24,35,3.48,0,0,7.54,4.42,10.87,7.66C103.43,45.62,104,46.48,104,47.09Z"
            />
            <path
              class="hb5-2"
              d="M17.2,48.8s-.59,3.94-11.73,4.29a10.6,10.6,0,0,1,2-4Z"
            />
            <path
              class="hb5-2"
              d="M104,47.09v1.64c-9.74-.53-9.88-4.08-9.88-4.08h8.32C103.43,45.62,104.05,46.48,104,47.09Z"
            />
            <circle class="hb5-2" cx="86.9" cy="64.79" r="9.85" />
            <circle class="hb5-2" cx="23.78" cy="64.79" r="9.85" />
            <line class="hb5-3" x1="30.08" y1="64.79" x2="17.48" y2="64.79" />
            <line class="hb5-3" x1="23.78" y1="71.09" x2="23.78" y2="58.5" />
            <line class="hb5-3" x1="28.23" y1="60.34" x2="19.33" y2="69.25" />
            <line class="hb5-3" x1="28.23" y1="69.25" x2="19.33" y2="60.34" />
            <path
              class="hb5-2"
              d="M60.48,50.55H56.15a.75.75,0,1,1,0-1.5h4.33a.75.75,0,1,1,0,1.5Z"
            />
            <path
              class="hb5-2"
              d="M84.48,50.55H80.15a.75.75,0,0,1,0-1.5h4.33a.75.75,0,0,1,0,1.5Z"
            />
            <path
              class="hb5-2"
              d="M59.3,37.46l2.47-.09L60.92,46c-9.58.42-21.78.86-23.17.48C37.75,46.44,49.2,37,59.3,37.46Z"
            />
            <path
              class="hb5-2"
              d="M79.43,37.44l-.74,6.41L72.6,45.4s-4.44.23-10.16.49l.84-8.57C67.33,37.22,73.8,37.14,79.43,37.44Z"
            />
            <path
              class="hb5-2"
              d="M88.78,38.75s-.16,1.29-2.16,3.08l-6.38,1.62.69-5.91A33.57,33.57,0,0,1,88.78,38.75Z"
            />
            <circle class="hb5-3" cx="23.78" cy="64.79" r="6.3" />
            <circle class="hb5-3" cx="86.9" cy="64.79" r="6.3" />
            <line class="hb5-3" x1="93.2" y1="64.79" x2="80.61" y2="64.79" />
            <line class="hb5-3" x1="86.9" y1="71.09" x2="86.9" y2="58.5" />
            <line class="hb5-3" x1="91.36" y1="60.34" x2="82.45" y2="69.25" />
            <line class="hb5-3" x1="91.36" y1="69.25" x2="82.45" y2="60.34" />
          </svg>
        ),
      },
    },
    {
      name: "3DR Hatchback",
      icon: {
        rearIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 108 108"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M104,58.67v4.7a2.32,2.32,0,0,1-1.06,2c-1.35.83-4,1.86-8.07.93L27,68.58H15.8S4.67,70,4.36,66.27c0,0-1.32-10.23,1.1-12,0,0,11.88-6.6,20.23-7.6,0,0,18.59-12.2,32.45-12.2,0,0,18.92.23,31.35,1.87a.89.89,0,0,1,1.11,1.13,1.12,1.12,0,0,0,.29,1c1.43,1.52,6.26,6.48,9,7.59a4.78,4.78,0,0,1,1,1.13,5.64,5.64,0,0,1,.38,5.55c-.09.23-.19.45-.31.69C101,53.4,103.78,54.28,104,58.67Z"
            />
            <path
              class="hb3-2"
              d="M7.55,56A.58.58,0,0,1,7.07,55a10.32,10.32,0,0,1,7-4s3.67-.92,3.91-.32a20.43,20.43,0,0,1-6.8,4.79,4.91,4.91,0,0,1-1.43.28Z"
            />
            <path
              class="hb3-2"
              d="M73.24,37.71l7.22.6a2.19,2.19,0,0,1,1.22.51c1.54,1.36,5.84,5.31,4.44,6.3l-11,.84S71.87,46.21,72,44v-5.2A1.11,1.11,0,0,1,73.24,37.71Z"
            />
            <path
              class="hb3-3"
              d="M35.14,45.37c9.42-8,27.63-8,32.57-7.82a1.17,1.17,0,0,1,1.12,1.31L68,46.33a1.19,1.19,0,0,1-1.09,1.05L37.05,49.5s-3.52.21-3-2.29A3.31,3.31,0,0,1,35.14,45.37Z"
            />
            <line class="hb3-4" x1="59.8" y1="51.3" x2="64.97" y2="50.71" />
            <path
              class="hb3-2"
              d="M101.34,52.71c-1-.56-5.52-3.08-6.55-5,0,0,0-.52.92-.56H101A5.64,5.64,0,0,1,101.34,52.71Z"
            />
            <path
              class="hb3-2"
              d="M90.28,58.12A7.7,7.7,0,1,0,98,65.82,7.7,7.7,0,0,0,90.28,58.12Z"
            />
            <path
              class="hb3-5"
              d="M90.28,60.39a5.43,5.43,0,1,0,5.42,5.43A5.43,5.43,0,0,0,90.28,60.39Z"
            />
            <path
              class="hb3-5"
              d="M90.28,64.32a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,90.28,64.32Z"
            />
            <line class="hb3-5" x1="90.28" y1="64.32" x2="87.25" y2="61.3" />
            <line class="hb3-5" x1="88.98" y1="65.07" x2="84.85" y2="66.18" />
            <line class="hb3-5" x1="88.98" y1="66.57" x2="87.87" y2="70.7" />
            <line class="hb3-5" x1="90.28" y1="67.32" x2="93.3" y2="70.34" />
            <line class="hb3-5" x1="91.57" y1="66.57" x2="95.7" y2="65.47" />
            <line class="hb3-5" x1="91.57" y1="65.07" x2="92.68" y2="60.95" />
            <path
              class="hb3-2"
              d="M21.5,58.12a7.7,7.7,0,1,0,7.7,7.7A7.7,7.7,0,0,0,21.5,58.12Z"
            />
            <path
              class="hb3-5"
              d="M21.5,60.39a5.43,5.43,0,1,0,5.43,5.43A5.42,5.42,0,0,0,21.5,60.39Z"
            />
            <path
              class="hb3-5"
              d="M21.5,64.32a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,21.5,64.32Z"
            />
            <line class="hb3-5" x1="21.5" y1="64.32" x2="18.48" y2="61.3" />
            <line class="hb3-5" x1="20.2" y1="65.07" x2="16.08" y2="66.18" />
            <line class="hb3-5" x1="20.2" y1="66.57" x2="19.1" y2="70.7" />
            <line class="hb3-5" x1="21.5" y1="67.32" x2="24.52" y2="70.34" />
            <line class="hb3-5" x1="22.8" y1="66.57" x2="26.93" y2="65.47" />
            <line class="hb3-5" x1="22.8" y1="65.07" x2="23.91" y2="60.95" />
          </svg>
        ),
        fullIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 108 108"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M104,58.67v4.7a2.32,2.32,0,0,1-1.06,2c-1.35.83-4,1.86-8.07.93L27,68.58H15.8S4.67,70,4.36,66.27c0,0-1.32-10.23,1.1-12,0,0,11.88-6.6,20.23-7.6,0,0,18.59-12.2,32.45-12.2,0,0,18.92.23,31.35,1.87,0,0,1.64-.33,1,1.65,0,0,6.16,6.71,9.47,8a4.78,4.78,0,0,1,1,1.13,5.64,5.64,0,0,1,.38,5.55c-.09.23-.19.45-.31.69C101,53.4,103.78,54.28,104,58.67Z"
            />
            <path
              class="hb3f-2"
              d="M7.55,56A.58.58,0,0,1,7.07,55a10.32,10.32,0,0,1,7-4s3.67-.92,3.91-.32a20.43,20.43,0,0,1-6.8,4.79,4.91,4.91,0,0,1-1.43.28Z"
            />
            <path
              class="hb3f-2"
              d="M73.24,37.71l7.22.6a2.19,2.19,0,0,1,1.22.51c1.54,1.36,5.84,5.31,4.44,6.3l-11,.84S71.87,46.21,72,44v-5.2A1.11,1.11,0,0,1,73.24,37.71Z"
            />
            <path
              class="hb3f-2"
              d="M35.14,45.37c9.42-8,27.63-8,32.57-7.82a1.17,1.17,0,0,1,1.12,1.31L68,46.33a1.19,1.19,0,0,1-1.09,1.05L37.05,49.5s-3.52.21-3-2.29A3.31,3.31,0,0,1,35.14,45.37Z"
            />
            <line class="hb3f-3" x1="59.8" y1="51.3" x2="64.97" y2="50.71" />
            <path
              class="hb3f-2"
              d="M101.34,52.71c-1-.56-5.52-3.08-6.55-5,0,0,0-.52.92-.56H101A5.64,5.64,0,0,1,101.34,52.71Z"
            />
            <path
              class="hb3f-2"
              d="M90.28,58.12A7.7,7.7,0,1,0,98,65.82,7.7,7.7,0,0,0,90.28,58.12Z"
            />
            <path
              class="hb3f-4"
              d="M90.28,60.39a5.43,5.43,0,1,0,5.42,5.43A5.43,5.43,0,0,0,90.28,60.39Z"
            />
            <path
              class="hb3f-4"
              d="M90.28,64.32a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,90.28,64.32Z"
            />
            <line class="hb3f-4" x1="90.28" y1="64.32" x2="87.25" y2="61.3" />
            <line class="hb3f-4" x1="88.98" y1="65.07" x2="84.85" y2="66.18" />
            <line class="hb3f-4" x1="88.98" y1="66.57" x2="87.87" y2="70.7" />
            <line class="hb3f-4" x1="90.28" y1="67.32" x2="93.3" y2="70.34" />
            <line class="hb3f-4" x1="91.57" y1="66.57" x2="95.7" y2="65.47" />
            <line class="hb3f-4" x1="91.57" y1="65.07" x2="92.68" y2="60.95" />
            <path
              class="hb3f-2"
              d="M21.5,58.12a7.7,7.7,0,1,0,7.7,7.7A7.7,7.7,0,0,0,21.5,58.12Z"
            />
            <path
              class="hb3f-4"
              d="M21.5,60.39a5.43,5.43,0,1,0,5.43,5.43A5.42,5.42,0,0,0,21.5,60.39Z"
            />
            <path
              class="hb3f-4"
              d="M21.5,64.32a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,21.5,64.32Z"
            />
            <line class="hb3f-4" x1="21.5" y1="64.32" x2="18.48" y2="61.3" />
            <line class="hb3f-4" x1="20.2" y1="65.07" x2="16.08" y2="66.18" />
            <line class="hb3f-4" x1="20.2" y1="66.57" x2="19.1" y2="70.7" />
            <line class="hb3f-4" x1="21.5" y1="67.32" x2="24.52" y2="70.34" />
            <line class="hb3f-4" x1="22.8" y1="66.57" x2="26.93" y2="65.47" />
            <line class="hb3f-4" x1="22.8" y1="65.07" x2="23.91" y2="60.95" />
          </svg>
        ),
      },
    },
    {
      name: "Sedan",
      icon: {
        rearIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 108 108"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M4,54.24v8.31a2,2,0,0,0,1.21,1.84,16.44,16.44,0,0,0,9.34,1.06l12.12-.28H79.81l10.64-1.58h8s4.49.56,4.91-2.59l.67-5.42a1.06,1.06,0,0,0-.28-.87L102.11,53V47.81a1,1,0,0,0-.6-.91,6.12,6.12,0,0,0-2.18-.53s-7.59-.83-8-.83h.37s-15-7-23.13-7.22c0,0-8.48-.1-14.44.39a21.67,21.67,0,0,0-7.68,2.08c-3.45,1.65-9.33,4.58-11.67,6.42C34.82,47.21,6.41,49.24,4,54.24Z"
            />
            <path
              class="sedr-2"
              d="M62.53,39.84,62,46a.49.49,0,0,0,.51.54L83,46a.58.58,0,0,0,.37-1c-2.15-1.88-8.13-5.84-20.35-5.65A.53.53,0,0,0,62.53,39.84Z"
            />
            <path
              class="sedr-3"
              d="M39,47.17l19-.5a.52.52,0,0,0,.46-.35l2-6.29a.49.49,0,0,0-.46-.65c-3.76-.06-13.3.46-21.31,6.9A.5.5,0,0,0,39,47.17Z"
            />
            <line class="sedr-4" x1="80.49" y1="49.81" x2="78.35" y2="49.81" />
            <line class="sedr-4" x1="58.47" y1="49.81" x2="56.33" y2="49.81" />
            <path
              class="sedr-2"
              d="M95.66,49.81a.81.81,0,0,0,.15,1c.66.62,2,1.81,3,1.9h3.29V48.86H96.87A2.6,2.6,0,0,0,95.66,49.81Z"
            />
            <path
              class="sedr-2"
              d="M5.16,54.9s0,1.6,1.48,1.47a26.32,26.32,0,0,0,5.18-2.67.46.46,0,0,0-.24-.81C9.58,52.63,4.68,52.26,5.16,54.9Z"
            />
            <circle class="sedr-2" cx="84.08" cy="61.91" r="7.78" />
            <circle class="sedr-2" cx="19" cy="61.91" r="7.78" />
            <path
              class="sedr-5"
              d="M13.45,61.91a5.53,5.53,0,0,0,1.29,3.56,5.6,5.6,0,0,0,2.2,1.59,5.5,5.5,0,0,0,4.12,0,5.74,5.74,0,0,0,2.2-1.59,5.53,5.53,0,0,0,1.29-3.56c0-.13,0-.24,0-.36a5.51,5.51,0,0,0-4.18-5,5.23,5.23,0,0,0-2.72,0A5.46,5.46,0,0,0,14.3,59a5.75,5.75,0,0,0-.84,2.59C13.45,61.66,13.45,61.78,13.45,61.91Z"
            />
            <path class="sedr-5" d="M19.77,60.57a1.1,1.1,0,0,1,.28.2" />
            <path class="sedr-5" d="M18,60.77a1,1,0,0,1,.28-.2" />
            <path
              class="sedr-5"
              d="M17.49,62.22a1.5,1.5,0,0,0,.1.32,1.46,1.46,0,0,0,1.25.9.27.27,0,0,0,.16,0,.29.29,0,0,0,.16,0,1.47,1.47,0,0,0,1.25-.88,1.28,1.28,0,0,0,.11-.33"
            />
            <path
              class="sedr-5"
              d="M17.64,56.54l.6,4a1.49,1.49,0,0,1,.76-.2,1.58,1.58,0,0,1,.77.2l.59-4a5.23,5.23,0,0,0-2.72,0Z"
            />
            <path
              class="sedr-5"
              d="M20.55,61.91a1.58,1.58,0,0,1,0,.32l4-.68A5.52,5.52,0,0,0,23.7,59l-3.65,1.82A1.53,1.53,0,0,1,20.55,61.91Z"
            />
            <line class="sedr-5" x1="19.16" y1="63.44" x2="21.06" y2="67.06" />
            <line class="sedr-5" x1="20.41" y1="62.56" x2="23.26" y2="65.47" />
            <line class="sedr-5" x1="14.74" y1="65.47" x2="17.59" y2="62.54" />
            <line class="sedr-5" x1="16.94" y1="67.06" x2="18.84" y2="63.44" />
            <path
              class="sedr-5"
              d="M17.49,62.22A1.46,1.46,0,0,1,18,60.77L14.3,59a5.75,5.75,0,0,0-.84,2.59Z"
            />
            <path class="sedr-5" d="M84.85,60.57a1,1,0,0,1,.28.2" />
            <path class="sedr-5" d="M83,60.77a1.1,1.1,0,0,1,.28-.2" />
            <path
              class="sedr-5"
              d="M82.57,62.22a1.1,1.1,0,0,0,.11.32,1.44,1.44,0,0,0,1.24.9.29.29,0,0,0,.16,0,.27.27,0,0,0,.16,0,1.49,1.49,0,0,0,1.26-.88,1.8,1.8,0,0,0,.1-.33"
            />
            <path
              class="sedr-5"
              d="M78.53,61.91a5.53,5.53,0,0,0,1.29,3.56A5.67,5.67,0,0,0,82,67.06a5.5,5.5,0,0,0,4.12,0,5.6,5.6,0,0,0,2.2-1.59,5.53,5.53,0,0,0,1.29-3.56c0-.13,0-.24,0-.36a5.52,5.52,0,0,0-.84-2.6,5.46,5.46,0,0,0-3.34-2.41,5.23,5.23,0,0,0-2.72,0A5.49,5.49,0,0,0,79.38,59a5.75,5.75,0,0,0-.84,2.59C78.53,61.66,78.53,61.78,78.53,61.91Z"
            />
            <path
              class="sedr-5"
              d="M82.72,56.54l.6,4a1.49,1.49,0,0,1,.76-.2,1.55,1.55,0,0,1,.77.2l.59-4a5.23,5.23,0,0,0-2.72,0Z"
            />
            <path
              class="sedr-5"
              d="M85.64,61.91a1.55,1.55,0,0,1,0,.32l4-.68a5.52,5.52,0,0,0-.84-2.6l-3.65,1.82A1.54,1.54,0,0,1,85.64,61.91Z"
            />
            <line class="sedr-5" x1="84.24" y1="63.44" x2="86.14" y2="67.06" />
            <line class="sedr-5" x1="85.5" y1="62.56" x2="88.34" y2="65.47" />
            <line class="sedr-5" x1="79.82" y1="65.47" x2="82.68" y2="62.54" />
            <line class="sedr-5" x1="82.02" y1="67.06" x2="83.92" y2="63.44" />
            <path
              class="sedr-5"
              d="M82.57,62.22a1.55,1.55,0,0,1,0-.31,1.53,1.53,0,0,1,.5-1.14L79.38,59a5.75,5.75,0,0,0-.84,2.59Z"
            />
          </svg>
        ),
        fullIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 108 108"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M4,54.24v8.31a2,2,0,0,0,1.21,1.84,16.44,16.44,0,0,0,9.34,1.06l12.12-.28H79.81l10.64-1.58h8s4.49.56,4.91-2.59l.67-5.42a1.06,1.06,0,0,0-.28-.87L102.11,53V47.81a1,1,0,0,0-.6-.91,6.12,6.12,0,0,0-2.18-.53s-7.59-.83-8-.83h.37s-15-7-23.13-7.22c0,0-8.48-.1-14.44.39a21.67,21.67,0,0,0-7.68,2.08c-3.45,1.65-9.33,4.58-11.67,6.42C34.82,47.21,6.41,49.24,4,54.24Z"
            />
            <path
              class="sedr-2"
              d="M62.53,39.84,62,46a.49.49,0,0,0,.51.54L83.2,46a.5.5,0,0,0,.32-.87c-2-1.83-8-6-20.49-5.78A.53.53,0,0,0,62.53,39.84Z"
            />
            <path
              class="sedr-2"
              d="M39,47.17l19-.5a.52.52,0,0,0,.46-.35l2-6.29a.49.49,0,0,0-.46-.65c-3.76-.06-13.3.46-21.31,6.9A.5.5,0,0,0,39,47.17Z"
            />
            <line class="sedr-3" x1="80.49" y1="49.81" x2="78.35" y2="49.81" />
            <line class="sedr-3" x1="58.47" y1="49.81" x2="56.33" y2="49.81" />
            <path
              class="sedr-2"
              d="M95.66,49.81a.81.81,0,0,0,.15,1c.66.62,2,1.81,3,1.9h3.29V48.86H96.87A2.6,2.6,0,0,0,95.66,49.81Z"
            />
            <path
              class="sedr-2"
              d="M5.16,54.9s0,1.6,1.48,1.47a26.32,26.32,0,0,0,5.18-2.67.46.46,0,0,0-.24-.81C9.58,52.63,4.68,52.26,5.16,54.9Z"
            />
            <circle class="sedr-2" cx="84.08" cy="61.91" r="7.78" />
            <circle class="sedr-2" cx="19" cy="61.91" r="7.78" />
            <path
              class="sedr-4"
              d="M13.45,61.91a5.53,5.53,0,0,0,1.29,3.56,5.6,5.6,0,0,0,2.2,1.59,5.5,5.5,0,0,0,4.12,0,5.74,5.74,0,0,0,2.2-1.59,5.53,5.53,0,0,0,1.29-3.56c0-.13,0-.24,0-.36a5.51,5.51,0,0,0-4.18-5,5.23,5.23,0,0,0-2.72,0A5.46,5.46,0,0,0,14.3,59a5.75,5.75,0,0,0-.84,2.59C13.45,61.66,13.45,61.78,13.45,61.91Z"
            />
            <path class="sedr-4" d="M19.77,60.57a1.1,1.1,0,0,1,.28.2" />
            <path class="sedr-4" d="M18,60.77a1,1,0,0,1,.28-.2" />
            <path
              class="sedr-4"
              d="M17.49,62.22a1.5,1.5,0,0,0,.1.32,1.46,1.46,0,0,0,1.25.9.27.27,0,0,0,.16,0,.29.29,0,0,0,.16,0,1.47,1.47,0,0,0,1.25-.88,1.28,1.28,0,0,0,.11-.33"
            />
            <path
              class="sedr-4"
              d="M17.64,56.54l.6,4a1.49,1.49,0,0,1,.76-.2,1.58,1.58,0,0,1,.77.2l.59-4a5.23,5.23,0,0,0-2.72,0Z"
            />
            <path
              class="sedr-4"
              d="M20.55,61.91a1.58,1.58,0,0,1,0,.32l4-.68A5.52,5.52,0,0,0,23.7,59l-3.65,1.82A1.53,1.53,0,0,1,20.55,61.91Z"
            />
            <line class="sedr-4" x1="19.16" y1="63.44" x2="21.06" y2="67.06" />
            <line class="sedr-4" x1="20.41" y1="62.56" x2="23.26" y2="65.47" />
            <line class="sedr-4" x1="14.74" y1="65.47" x2="17.59" y2="62.54" />
            <line class="sedr-4" x1="16.94" y1="67.06" x2="18.84" y2="63.44" />
            <path
              class="sedr-4"
              d="M17.49,62.22A1.46,1.46,0,0,1,18,60.77L14.3,59a5.75,5.75,0,0,0-.84,2.59Z"
            />
            <path class="sedr-4" d="M84.85,60.57a1,1,0,0,1,.28.2" />
            <path class="sedr-4" d="M83,60.77a1.1,1.1,0,0,1,.28-.2" />
            <path
              class="sedr-4"
              d="M82.57,62.22a1.1,1.1,0,0,0,.11.32,1.44,1.44,0,0,0,1.24.9.29.29,0,0,0,.16,0,.27.27,0,0,0,.16,0,1.49,1.49,0,0,0,1.26-.88,1.8,1.8,0,0,0,.1-.33"
            />
            <path
              class="sedr-4"
              d="M78.53,61.91a5.53,5.53,0,0,0,1.29,3.56A5.67,5.67,0,0,0,82,67.06a5.5,5.5,0,0,0,4.12,0,5.6,5.6,0,0,0,2.2-1.59,5.53,5.53,0,0,0,1.29-3.56c0-.13,0-.24,0-.36a5.52,5.52,0,0,0-.84-2.6,5.46,5.46,0,0,0-3.34-2.41,5.23,5.23,0,0,0-2.72,0A5.49,5.49,0,0,0,79.38,59a5.75,5.75,0,0,0-.84,2.59C78.53,61.66,78.53,61.78,78.53,61.91Z"
            />
            <path
              class="sedr-4"
              d="M82.72,56.54l.6,4a1.49,1.49,0,0,1,.76-.2,1.55,1.55,0,0,1,.77.2l.59-4a5.23,5.23,0,0,0-2.72,0Z"
            />
            <path
              class="sedr-4"
              d="M85.64,61.91a1.55,1.55,0,0,1,0,.32l4-.68a5.52,5.52,0,0,0-.84-2.6l-3.65,1.82A1.54,1.54,0,0,1,85.64,61.91Z"
            />
            <line class="sedr-4" x1="84.24" y1="63.44" x2="86.14" y2="67.06" />
            <line class="sedr-4" x1="85.5" y1="62.56" x2="88.34" y2="65.47" />
            <line class="sedr-4" x1="79.82" y1="65.47" x2="82.68" y2="62.54" />
            <line class="sedr-4" x1="82.02" y1="67.06" x2="83.92" y2="63.44" />
            <path
              class="sedr-4"
              d="M82.57,62.22a1.55,1.55,0,0,1,0-.31,1.53,1.53,0,0,1,.5-1.14L79.38,59a5.75,5.75,0,0,0-.84,2.59Z"
            />
          </svg>
        ),
      },
    },
    {
      name: "Compact SUV",
      icon: {
        rearIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 108 108"
          >
            <defs></defs>
            <path
              class="csuv-1"
              d="M99.85,44.78a3.65,3.65,0,0,1,2.28,2.12s2.13,4.5-.13,5.41"
            />
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M104,54.77V60.4c-.92,3.55-5.15,3-5.15,3l-16.7,2.28L27.43,67.72,12,68.86c-.8-.79-5.49-1.14-5.49-1.14a4.22,4.22,0,0,1-2.35-1.13.5.5,0,0,1,.16-.86l1.5-.52C4.54,61.1,4.7,58.08,5.3,56a9.32,9.32,0,0,1,1-2.37,5.16,5.16,0,0,1,2.17-2,56.11,56.11,0,0,1,5.82-2.36,114.44,114.44,0,0,1,19-4.55L44.39,35.8c18.19-3.84,41.39-1.46,45.48-1a.5.5,0,0,1,.4.68l-.32.86a.53.53,0,0,0,.18.61l8.15,6.28c3,1.26,3.77,9.49,3.77,9.49l1.51,1.16A1.09,1.09,0,0,1,104,54.77Z"
            />
            <path
              class="csuv-3"
              d="M48.91,36.71l13.72-.41a1,1,0,0,1,1,1.29l-2,6.9a1,1,0,0,1-.9.72L40.27,46.42a.5.5,0,0,1-.41-.83l6.87-7.86A3,3,0,0,1,48.91,36.71Z"
            />
            <path
              class="csuv-1"
              d="M79.45,36.49l2.33,6.17A1,1,0,0,1,80.9,44l-14.19.81a1,1,0,0,1-1.07-1V37.18a1,1,0,0,1,1-1l11.86-.33A1,1,0,0,1,79.45,36.49Z"
            />
            <path
              class="csuv-1"
              d="M89.32,43.46l-4.56.27a.48.48,0,0,1-.5-.32l-2.66-7a.5.5,0,0,1,.46-.68L85,35.63a.48.48,0,0,1,.43.23l4.31,6.83A.5.5,0,0,1,89.32,43.46Z"
            />
            <line class="csuv-4" x1="82.26" y1="43.94" x2="79.19" y2="35.82" />
            <line class="csuv-1" x1="82.36" y1="35.73" x2="85.39" y2="43.76" />
            <path
              class="csuv-1"
              d="M10.93,53.77S7.61,56.68,5.3,56a9.71,9.71,0,0,1,1-2.35,5.37,5.37,0,0,1,2.27-2,57.76,57.76,0,0,1,5.74-2.32S14.61,51.42,10.93,53.77Z"
            />
            <path
              class="csuv-1"
              d="M20.71,56.52a8.92,8.92,0,1,0,8.92,8.91A8.91,8.91,0,0,0,20.71,56.52Z"
            />
            <path
              class="csuv-5"
              d="M20.71,63.42a2,2,0,1,0,2,2A2,2,0,0,0,20.71,63.42Z"
            />
            <path
              class="csuv-5"
              d="M27.24,65.43A6.22,6.22,0,0,1,27,67.28a6.51,6.51,0,0,1-4.15,4.33,6.4,6.4,0,0,1-2.11.35,6.51,6.51,0,0,1-6.25-4.68,5.92,5.92,0,0,1-.28-1.85,6.23,6.23,0,0,1,.41-2.24,6.52,6.52,0,0,1,6.12-4.29,6.41,6.41,0,0,1,2,.31,6.54,6.54,0,0,1,4.55,6.22Z"
            />
            <path
              class="csuv-5"
              d="M20.1,63.52l-1.37-4.31a6.51,6.51,0,0,1,2-.31,6.41,6.41,0,0,1,2,.31l-1.36,4.31"
            />
            <path
              class="csuv-5"
              d="M21.37,67.33l1.45,4.28a6.4,6.4,0,0,1-2.11.35,6.49,6.49,0,0,1-2.11-.35l1.46-4.28"
            />
            <path
              class="csuv-5"
              d="M22.59,64.74l4.25-1.55a6.44,6.44,0,0,1,.4,2.24A6.22,6.22,0,0,1,27,67.28L22.64,66"
            />
            <path
              class="csuv-5"
              d="M18.78,66l-4.32,1.28a5.92,5.92,0,0,1-.28-1.85,6.23,6.23,0,0,1,.41-2.24l4.24,1.55"
            />
            <path
              class="csuv-1"
              d="M91.18,56.52a8.92,8.92,0,1,0,8.91,8.91A8.91,8.91,0,0,0,91.18,56.52Z"
            />
            <path
              class="csuv-5"
              d="M91.18,63.42a2,2,0,1,0,2,2A2,2,0,0,0,91.18,63.42Z"
            />
            <path
              class="csuv-5"
              d="M97.71,65.43a5.92,5.92,0,0,1-.28,1.85A6.51,6.51,0,0,1,91.18,72a6.4,6.4,0,0,1-2.11-.35,6.51,6.51,0,0,1-4.15-4.33,6.22,6.22,0,0,1-.27-1.85,6.54,6.54,0,0,1,6.53-6.53,6.58,6.58,0,0,1,6.53,6.53Z"
            />
            <path
              class="csuv-5"
              d="M90.56,63.52,89.2,59.21a6.41,6.41,0,0,1,2-.31,6.51,6.51,0,0,1,2,.31l-1.37,4.31"
            />
            <path
              class="csuv-5"
              d="M91.83,67.33l1.46,4.28a6.49,6.49,0,0,1-2.11.35,6.4,6.4,0,0,1-2.11-.35l1.45-4.28"
            />
            <path
              class="csuv-5"
              d="M93.05,64.74l4.25-1.55a6.45,6.45,0,0,1,.41,2.24,5.92,5.92,0,0,1-.28,1.85L93.1,66"
            />
            <path
              class="csuv-5"
              d="M89.25,66l-4.33,1.28a6.22,6.22,0,0,1-.27-1.85,6.44,6.44,0,0,1,.4-2.24l4.25,1.55"
            />
            <line class="csuv-6" x1="55.35" y1="49.98" x2="60.97" y2="49.63" />
            <line class="csuv-6" x1="77.21" y1="48.66" x2="82.81" y2="48.1" />
          </svg>
        ),
        fullIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 108 108"
          >
            <defs></defs>
            <path
              class="csuv-1"
              d="M99.85,44.78a3.65,3.65,0,0,1,2.28,2.12s2.13,4.5-.13,5.41"
            />
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M104,54.77V60.4c-.92,3.55-5.15,3-5.15,3l-16.7,2.28L27.43,67.72,12,68.86c-.8-.79-5.49-1.14-5.49-1.14a4.22,4.22,0,0,1-2.35-1.13.5.5,0,0,1,.16-.86l1.5-.52C4.54,61.1,4.7,58.08,5.3,56a9.32,9.32,0,0,1,1-2.37,5.16,5.16,0,0,1,2.17-2,56.11,56.11,0,0,1,5.82-2.36,114.44,114.44,0,0,1,19-4.55L44.39,35.8c18.19-3.84,41.39-1.46,45.48-1a.5.5,0,0,1,.4.68l-.32.86a.53.53,0,0,0,.18.61l8.15,6.28c3,1.26,3.77,9.49,3.77,9.49l1.51,1.16A1.09,1.09,0,0,1,104,54.77Z"
            />
            <path
              class="csuv-1"
              d="M48.91,36.71l13.72-.41a1,1,0,0,1,1,1.29l-2,6.9a1,1,0,0,1-.9.72L40.27,46.42a.5.5,0,0,1-.41-.83l6.87-7.86A3,3,0,0,1,48.91,36.71Z"
            />
            <path
              class="csuv-1"
              d="M79.45,36.49l2.33,6.17A1,1,0,0,1,80.9,44l-14.19.81a1,1,0,0,1-1.07-1V37.18a1,1,0,0,1,1-1l11.86-.33A1,1,0,0,1,79.45,36.49Z"
            />
            <path
              class="csuv-1"
              d="M89.32,43.46l-4.56.27a.48.48,0,0,1-.5-.32l-2.66-7a.5.5,0,0,1,.46-.68L85,35.63a.48.48,0,0,1,.43.23l4.31,6.83A.5.5,0,0,1,89.32,43.46Z"
            />
            <line class="csuv-3" x1="82.26" y1="43.94" x2="79.19" y2="35.82" />
            <line class="csuv-1" x1="82.36" y1="35.73" x2="85.39" y2="43.76" />
            <path
              class="csuv-1"
              d="M10.93,53.77S7.61,56.68,5.3,56a9.71,9.71,0,0,1,1-2.35,5.37,5.37,0,0,1,2.27-2,57.76,57.76,0,0,1,5.74-2.32S14.61,51.42,10.93,53.77Z"
            />
            <path
              class="csuv-1"
              d="M20.71,56.52a8.92,8.92,0,1,0,8.92,8.91A8.91,8.91,0,0,0,20.71,56.52Z"
            />
            <path
              class="csuv-4"
              d="M20.71,63.42a2,2,0,1,0,2,2A2,2,0,0,0,20.71,63.42Z"
            />
            <path
              class="csuv-4"
              d="M27.24,65.43A6.22,6.22,0,0,1,27,67.28a6.51,6.51,0,0,1-4.15,4.33,6.4,6.4,0,0,1-2.11.35,6.51,6.51,0,0,1-6.25-4.68,5.92,5.92,0,0,1-.28-1.85,6.23,6.23,0,0,1,.41-2.24,6.52,6.52,0,0,1,6.12-4.29,6.41,6.41,0,0,1,2,.31,6.54,6.54,0,0,1,4.55,6.22Z"
            />
            <path
              class="csuv-4"
              d="M20.1,63.52l-1.37-4.31a6.51,6.51,0,0,1,2-.31,6.41,6.41,0,0,1,2,.31l-1.36,4.31"
            />
            <path
              class="csuv-4"
              d="M21.37,67.33l1.45,4.28a6.4,6.4,0,0,1-2.11.35,6.49,6.49,0,0,1-2.11-.35l1.46-4.28"
            />
            <path
              class="csuv-4"
              d="M22.59,64.74l4.25-1.55a6.44,6.44,0,0,1,.4,2.24A6.22,6.22,0,0,1,27,67.28L22.64,66"
            />
            <path
              class="csuv-4"
              d="M18.78,66l-4.32,1.28a5.92,5.92,0,0,1-.28-1.85,6.23,6.23,0,0,1,.41-2.24l4.24,1.55"
            />
            <path
              class="csuv-1"
              d="M91.18,56.52a8.92,8.92,0,1,0,8.91,8.91A8.91,8.91,0,0,0,91.18,56.52Z"
            />
            <path
              class="csuv-4"
              d="M91.18,63.42a2,2,0,1,0,2,2A2,2,0,0,0,91.18,63.42Z"
            />
            <path
              class="csuv-4"
              d="M97.71,65.43a5.92,5.92,0,0,1-.28,1.85A6.51,6.51,0,0,1,91.18,72a6.4,6.4,0,0,1-2.11-.35,6.51,6.51,0,0,1-4.15-4.33,6.22,6.22,0,0,1-.27-1.85,6.54,6.54,0,0,1,6.53-6.53,6.58,6.58,0,0,1,6.53,6.53Z"
            />
            <path
              class="csuv-4"
              d="M90.56,63.52,89.2,59.21a6.41,6.41,0,0,1,2-.31,6.51,6.51,0,0,1,2,.31l-1.37,4.31"
            />
            <path
              class="csuv-4"
              d="M91.83,67.33l1.46,4.28a6.49,6.49,0,0,1-2.11.35,6.4,6.4,0,0,1-2.11-.35l1.45-4.28"
            />
            <path
              class="csuv-4"
              d="M93.05,64.74l4.25-1.55a6.45,6.45,0,0,1,.41,2.24,5.92,5.92,0,0,1-.28,1.85L93.1,66"
            />
            <path
              class="csuv-4"
              d="M89.25,66l-4.33,1.28a6.22,6.22,0,0,1-.27-1.85,6.44,6.44,0,0,1,.4-2.24l4.25,1.55"
            />
            <line class="csuv-5" x1="55.35" y1="49.98" x2="60.97" y2="49.63" />
            <line class="csuv-5" x1="77.21" y1="48.66" x2="82.81" y2="48.1" />
          </svg>
        ),
      },
    },
    {
      name: "Coupe",
      icon: {
        rearIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 108 108"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M101.81,62.61a1.73,1.73,0,0,1-1.3.66l-2.75.09a.84.84,0,0,1-.82-.46c-.44-1-2.57-1.41-2.57-1.41l-.57,1.82-72,.7c-6.11-.24-8.77.4-9.82.81a4.15,4.15,0,0,1-1.78.26c-4.31-.24-5.57-1.22-5.87-1.53a.32.32,0,0,1-.08-.21V62.12H5.39V57.93l-1-.5V56l.35-.77v-.62C4.28,54.43,4,53.51,4,53.51c9.16-8.31,33.64-7.41,33.64-7.41l14.62-6C59.33,38.2,70,39.32,70,39.32c4.12.69,20.64,4.9,20.64,4.9,2.77.53,9.35,1.16,11.34,1.34a.38.38,0,0,1,.31.56l-.88,1.66.06.22.84,3.2,1.07.43a.53.53,0,0,1,.34.5V56C104.69,58.48,102.78,61.37,101.81,62.61Z"
            />
            <path
              class="cou-2"
              d="M71.43,41.81l-1,3.94a.3.3,0,0,0,.33.37c5.79-.58,7.76-1.79,8.41-2.41A.31.31,0,0,0,79,43.2a49.1,49.1,0,0,0-7.25-1.62A.3.3,0,0,0,71.43,41.81Z"
            />
            <path
              class="cou-3"
              d="M69.64,41.87,68.57,46a.48.48,0,0,1-.44.37c-2.25.13-4.95.17-8.18.09l-16.26.23a.3.3,0,0,1-.17-.56c2.17-1.33,7.91-4.6,12.87-5a64,64,0,0,1,12.81.15A.52.52,0,0,1,69.64,41.87Z"
            />
            <path
              class="cou-2"
              d="M4,53.51s.29.92.77,1.13v.62l.71.42s4.73-1.58,5.8-3.31c0,0,.06-1.48-1.12-1.34A20.08,20.08,0,0,0,4,53.51Z"
            />
            <path
              class="cou-2"
              d="M98.48,50.86l3.85.34L101.5,48l-.07-.22-3.09,2.61A.27.27,0,0,0,98.48,50.86Z"
            />
            <path
              class="cou-2"
              d="M87.35,51.38a8.82,8.82,0,1,1-8.82,8.82A8.82,8.82,0,0,1,87.35,51.38Z"
            />
            <path
              class="cou-4"
              d="M93.73,60.2a6.44,6.44,0,0,1-3.39,5.63,6.35,6.35,0,0,1-3,.75,6.28,6.28,0,0,1-3-.75,6.16,6.16,0,0,1-2.24-2,6.34,6.34,0,0,1-.4-6.63A6.38,6.38,0,0,1,91,55a6.36,6.36,0,0,1,2.75,5.24Z"
            />
            <path
              class="cou-4"
              d="M87.35,57.7a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,87.35,57.7Z"
            />
            <path
              class="cou-4"
              d="M85,61l-2.86,2.85a6.16,6.16,0,0,0,2.24,2l3-3,.15-.15"
            />
            <path
              class="cou-4"
              d="M88.12,57.83,91,55a6.25,6.25,0,0,1,2,2.24l-3.15,3.15"
            />
            <path
              class="cou-4"
              d="M87.2,62.69l.15.15,3,3a6.25,6.25,0,0,0,2.24-2L89.72,61"
            />
            <path
              class="cou-4"
              d="M84.85,60.34,81.71,57.2a6.34,6.34,0,0,1,2-2.24l2.86,2.86"
            />
            <path
              class="cou-2"
              d="M21.42,51.38a8.82,8.82,0,1,1-8.81,8.82A8.82,8.82,0,0,1,21.42,51.38Z"
            />
            <path
              class="cou-4"
              d="M27.8,60.2a6.44,6.44,0,0,1-3.39,5.63,6.35,6.35,0,0,1-3,.75,6.28,6.28,0,0,1-3-.75,6.16,6.16,0,0,1-2.24-2,6.34,6.34,0,0,1-.4-6.63A6.38,6.38,0,0,1,25.05,55,6.36,6.36,0,0,1,27.8,60.2Z"
            />
            <path
              class="cou-4"
              d="M21.42,57.7a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,21.42,57.7Z"
            />
            <path
              class="cou-4"
              d="M19,61l-2.86,2.85a6.16,6.16,0,0,0,2.24,2l3-3,.15-.15"
            />
            <path
              class="cou-4"
              d="M22.19,57.83,25.05,55a6.25,6.25,0,0,1,2,2.24L23.9,60.35"
            />
            <path
              class="cou-4"
              d="M21.27,62.69l.15.15,3,3a6.25,6.25,0,0,0,2.24-2L23.79,61"
            />
            <path
              class="cou-4"
              d="M18.92,60.34,15.78,57.2a6.34,6.34,0,0,1,2-2.24l2.86,2.86"
            />
          </svg>
        ),
        fullIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 108 108"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M101.81,62.61a1.73,1.73,0,0,1-1.3.66l-2.75.09a.84.84,0,0,1-.82-.46c-.44-1-2.57-1.41-2.57-1.41l-.57,1.82-72,.7c-6.11-.24-8.77.4-9.82.81a4.15,4.15,0,0,1-1.78.26c-4.31-.24-5.57-1.22-5.87-1.53a.32.32,0,0,1-.08-.21V62.12H5.39V57.93l-1-.5V56l.35-.77v-.62C4.28,54.43,4,53.51,4,53.51c9.16-8.31,33.64-7.41,33.64-7.41l14.62-6C59.33,38.2,70,39.32,70,39.32c4.12.69,20.64,4.9,20.64,4.9,2.77.53,9.35,1.16,11.34,1.34a.38.38,0,0,1,.31.56l-.88,1.66.06.22.84,3.2,1.07.43a.53.53,0,0,1,.34.5V56C104.69,58.48,102.78,61.37,101.81,62.61Z"
            />
            <path
              class="cou-2"
              d="M71.43,41.81l-1,3.94a.3.3,0,0,0,.33.37c5.79-.58,7.76-1.79,8.41-2.41A.31.31,0,0,0,79,43.2a49.1,49.1,0,0,0-7.25-1.62A.3.3,0,0,0,71.43,41.81Z"
            />
            <path
              class="cou-2"
              d="M69.64,41.87,68.57,46a.48.48,0,0,1-.44.37c-2.25.13-4.95.17-8.18.09l-16.26.23a.3.3,0,0,1-.17-.56c2.17-1.33,7.91-4.6,12.87-5a64,64,0,0,1,12.81.15A.52.52,0,0,1,69.64,41.87Z"
            />
            <path
              class="cou-2"
              d="M4,53.51s.29.92.77,1.13v.62l.71.42s4.73-1.58,5.8-3.31c0,0,.06-1.48-1.12-1.34A20.08,20.08,0,0,0,4,53.51Z"
            />
            <path
              class="cou-2"
              d="M98.48,50.86l3.85.34L101.5,48l-.07-.22-3.09,2.61A.27.27,0,0,0,98.48,50.86Z"
            />
            <path
              class="cou-2"
              d="M87.35,51.38a8.82,8.82,0,1,1-8.82,8.82A8.82,8.82,0,0,1,87.35,51.38Z"
            />
            <path
              class="cou-3"
              d="M93.73,60.2a6.44,6.44,0,0,1-3.39,5.63,6.35,6.35,0,0,1-3,.75,6.28,6.28,0,0,1-3-.75,6.16,6.16,0,0,1-2.24-2,6.34,6.34,0,0,1-.4-6.63A6.38,6.38,0,0,1,91,55a6.36,6.36,0,0,1,2.75,5.24Z"
            />
            <path
              class="cou-3"
              d="M87.35,57.7a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,87.35,57.7Z"
            />
            <path
              class="cou-3"
              d="M85,61l-2.86,2.85a6.16,6.16,0,0,0,2.24,2l3-3,.15-.15"
            />
            <path
              class="cou-3"
              d="M88.12,57.83,91,55a6.25,6.25,0,0,1,2,2.24l-3.15,3.15"
            />
            <path
              class="cou-3"
              d="M87.2,62.69l.15.15,3,3a6.25,6.25,0,0,0,2.24-2L89.72,61"
            />
            <path
              class="cou-3"
              d="M84.85,60.34,81.71,57.2a6.34,6.34,0,0,1,2-2.24l2.86,2.86"
            />
            <path
              class="cou-2"
              d="M21.42,51.38a8.82,8.82,0,1,1-8.81,8.82A8.82,8.82,0,0,1,21.42,51.38Z"
            />
            <path
              class="cou-3"
              d="M27.8,60.2a6.44,6.44,0,0,1-3.39,5.63,6.35,6.35,0,0,1-3,.75,6.28,6.28,0,0,1-3-.75,6.16,6.16,0,0,1-2.24-2,6.34,6.34,0,0,1-.4-6.63A6.38,6.38,0,0,1,25.05,55,6.36,6.36,0,0,1,27.8,60.2Z"
            />
            <path
              class="cou-3"
              d="M21.42,57.7a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,21.42,57.7Z"
            />
            <path
              class="cou-3"
              d="M19,61l-2.86,2.85a6.16,6.16,0,0,0,2.24,2l3-3,.15-.15"
            />
            <path
              class="cou-3"
              d="M22.19,57.83,25.05,55a6.25,6.25,0,0,1,2,2.24L23.9,60.35"
            />
            <path
              class="cou-3"
              d="M21.27,62.69l.15.15,3,3a6.25,6.25,0,0,0,2.24-2L23.79,61"
            />
            <path
              class="cou-3"
              d="M18.92,60.34,15.78,57.2a6.34,6.34,0,0,1,2-2.24l2.86,2.86"
            />
          </svg>
        ),
      },
    },
    {
      name: "2DR Truck",
      icon: {
        rearIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 270 270"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M260.88,148.82V118a3.94,3.94,0,0,0-3.94-4H162.58l-7.65-25a7.31,7.31,0,0,0-7-5.18H129A64.86,64.86,0,0,0,90.76,96.32L68.62,112.47,19.08,123.7a7.2,7.2,0,0,0-5.4,4.48c-.06.15-.11.3-.16.46l-.06.19a50.59,50.59,0,0,0-1.57,6.87,48.89,48.89,0,0,0-.6,7.59c0,.5,0,1,0,1.52v.09l.06,1.16h-.52A3.86,3.86,0,0,0,7,149.92v4.49a6.84,6.84,0,0,0,5.78,6.76l12.65,4.28H258.15a4.86,4.86,0,0,0,4.85-4.86V151A2.22,2.22,0,0,0,260.88,148.82ZM96.34,99.58A58.55,58.55,0,0,1,129,89.71h18.93a1.45,1.45,0,0,1,1.39,1l6.9,22.57a2.93,2.93,0,0,1-2.8,3.79H97Z"
            />
            <path
              class="tru-2"
              d="M153.42,117.09H72.24l24.1-17.51A58.55,58.55,0,0,1,129,89.71h18.93a1.45,1.45,0,0,1,1.39,1l6.9,22.57A2.93,2.93,0,0,1,153.42,117.09Z"
            />
            <path
              class="tru-3"
              d="M13.52,128.64c.05-.16.1-.31.16-.46h10a3.15,3.15,0,0,1,2.86,4.47l-4.79,10.46A3.07,3.07,0,0,1,19,144.9H11.32A49.54,49.54,0,0,1,13.52,128.64Z"
            />
            <path
              class="tru-3"
              d="M260.88,122.56h-6.26a1.16,1.16,0,0,0-1.14,1.41l2.66,12.1a1.16,1.16,0,0,0,1,.9l3.76.33Z"
            />
            <circle class="tru-3" cx="57.27" cy="163.89" r="22.27" />
            <circle
              class="tru-4"
              cx="57.27"
              cy="163.89"
              r="13.88"
              transform="translate(-99.12 88.5) rotate(-45)"
            />
            <circle
              class="tru-4"
              cx="57.27"
              cy="163.89"
              r="13"
              transform="translate(-99.12 88.5) rotate(-45)"
            />
            <path
              class="tru-3"
              d="M53.9,175.3l.62-1.49a2.58,2.58,0,0,0-.87-3.07l-1.77-1.29a2.57,2.57,0,0,0-3.19.13l-1.22,1A11.85,11.85,0,0,0,53.9,175.3Z"
            />
            <path
              class="tru-3"
              d="M49.64,162.57l.68-2.09a2.58,2.58,0,0,0-1.1-3l-1.38-.84a11.87,11.87,0,0,0-2.46,7.24v.32l1.61.13A2.57,2.57,0,0,0,49.64,162.57Z"
            />
            <path
              class="tru-3"
              d="M67.08,170.63l-1.23-1a2.57,2.57,0,0,0-3.19-.13l-1.77,1.29a2.57,2.57,0,0,0-.86,3.07l.62,1.49A11.92,11.92,0,0,0,67.08,170.63Z"
            />
            <path
              class="tru-3"
              d="M66.71,156.65l-1.38.84a2.58,2.58,0,0,0-1.11,3l.68,2.09a2.59,2.59,0,0,0,2.65,1.77l1.61-.13c0-.11,0-.21,0-.32A11.87,11.87,0,0,0,66.71,156.65Z"
            />
            <path
              class="tru-3"
              d="M60.87,154.25l.38-1.57a12,12,0,0,0-8,0l.37,1.57a2.59,2.59,0,0,0,2.51,2h2.19A2.57,2.57,0,0,0,60.87,154.25Z"
            />
            <circle class="tru-3" cx="214.61" cy="163.89" r="22.27" />
            <circle
              class="tru-4"
              cx="214.61"
              cy="163.89"
              r="13.88"
              transform="translate(-53.03 199.75) rotate(-45)"
            />
            <circle
              class="tru-4"
              cx="214.61"
              cy="163.89"
              r="13"
              transform="translate(-53.03 199.75) rotate(-45)"
            />
            <path
              class="tru-3"
              d="M211.23,175.3l.62-1.49a2.57,2.57,0,0,0-.86-3.07l-1.78-1.29a2.56,2.56,0,0,0-3.18.13l-1.23,1A11.92,11.92,0,0,0,211.23,175.3Z"
            />
            <path
              class="tru-3"
              d="M207,162.57l.67-2.09a2.58,2.58,0,0,0-1.1-3l-1.38-.84a11.87,11.87,0,0,0-2.46,7.24c0,.11,0,.21,0,.32l1.6.13A2.59,2.59,0,0,0,207,162.57Z"
            />
            <path
              class="tru-3"
              d="M224.41,170.63l-1.23-1a2.56,2.56,0,0,0-3.18-.13l-1.78,1.29a2.57,2.57,0,0,0-.86,3.07l.62,1.49A11.92,11.92,0,0,0,224.41,170.63Z"
            />
            <path
              class="tru-3"
              d="M224,156.65l-1.38.84a2.58,2.58,0,0,0-1.1,3l.67,2.09a2.59,2.59,0,0,0,2.66,1.77l1.6-.13c0-.11,0-.21,0-.32A11.87,11.87,0,0,0,224,156.65Z"
            />
            <path
              class="tru-3"
              d="M218.2,154.25l.38-1.57a12,12,0,0,0-8,0l.38,1.57a2.57,2.57,0,0,0,2.5,2h2.19A2.57,2.57,0,0,0,218.2,154.25Z"
            />
            <path
              class="tru-3"
              d="M140.62,128.16a2.2,2.2,0,0,0,2.19,2.2h11.35a2.2,2.2,0,0,0,0-4.39H142.81A2.2,2.2,0,0,0,140.62,128.16Z"
            />
          </svg>
        ),
        fullIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 270 270"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M260.88,148.82V118a3.94,3.94,0,0,0-3.94-4H162.58l-7.65-25a7.31,7.31,0,0,0-7-5.18H129A64.86,64.86,0,0,0,90.76,96.32L68.62,112.47,19.08,123.7a7.2,7.2,0,0,0-5.4,4.48c-.06.15-.11.3-.16.46l-.06.19a50.59,50.59,0,0,0-1.57,6.87,48.89,48.89,0,0,0-.6,7.59c0,.5,0,1,0,1.52v.09l.06,1.16h-.52A3.86,3.86,0,0,0,7,149.92v4.49a6.84,6.84,0,0,0,5.78,6.76l12.65,4.28H258.15a4.86,4.86,0,0,0,4.85-4.86V151A2.22,2.22,0,0,0,260.88,148.82ZM96.34,99.58A58.55,58.55,0,0,1,129,89.71h18.93a1.45,1.45,0,0,1,1.39,1l6.9,22.57a2.93,2.93,0,0,1-2.8,3.79H97Z"
            />
            <path
              class="tru-3"
              d="M153.42,117.09H72.24l24.1-17.51A58.55,58.55,0,0,1,129,89.71h18.93a1.45,1.45,0,0,1,1.39,1l6.9,22.57A2.93,2.93,0,0,1,153.42,117.09Z"
            />
            <path
              class="tru-3"
              d="M13.52,128.64c.05-.16.1-.31.16-.46h10a3.15,3.15,0,0,1,2.86,4.47l-4.79,10.46A3.07,3.07,0,0,1,19,144.9H11.32A49.54,49.54,0,0,1,13.52,128.64Z"
            />
            <path
              class="tru-3"
              d="M260.88,122.56h-6.26a1.16,1.16,0,0,0-1.14,1.41l2.66,12.1a1.16,1.16,0,0,0,1,.9l3.76.33Z"
            />
            <circle class="tru-3" cx="57.27" cy="163.89" r="22.27" />
            <circle
              class="tru-4"
              cx="57.27"
              cy="163.89"
              r="13.88"
              transform="translate(-99.12 88.5) rotate(-45)"
            />
            <circle
              class="tru-4"
              cx="57.27"
              cy="163.89"
              r="13"
              transform="translate(-99.12 88.5) rotate(-45)"
            />
            <path
              class="tru-3"
              d="M53.9,175.3l.62-1.49a2.58,2.58,0,0,0-.87-3.07l-1.77-1.29a2.57,2.57,0,0,0-3.19.13l-1.22,1A11.85,11.85,0,0,0,53.9,175.3Z"
            />
            <path
              class="tru-3"
              d="M49.64,162.57l.68-2.09a2.58,2.58,0,0,0-1.1-3l-1.38-.84a11.87,11.87,0,0,0-2.46,7.24v.32l1.61.13A2.57,2.57,0,0,0,49.64,162.57Z"
            />
            <path
              class="tru-3"
              d="M67.08,170.63l-1.23-1a2.57,2.57,0,0,0-3.19-.13l-1.77,1.29a2.57,2.57,0,0,0-.86,3.07l.62,1.49A11.92,11.92,0,0,0,67.08,170.63Z"
            />
            <path
              class="tru-3"
              d="M66.71,156.65l-1.38.84a2.58,2.58,0,0,0-1.11,3l.68,2.09a2.59,2.59,0,0,0,2.65,1.77l1.61-.13c0-.11,0-.21,0-.32A11.87,11.87,0,0,0,66.71,156.65Z"
            />
            <path
              class="tru-3"
              d="M60.87,154.25l.38-1.57a12,12,0,0,0-8,0l.37,1.57a2.59,2.59,0,0,0,2.51,2h2.19A2.57,2.57,0,0,0,60.87,154.25Z"
            />
            <circle class="tru-3" cx="214.61" cy="163.89" r="22.27" />
            <circle
              class="tru-4"
              cx="214.61"
              cy="163.89"
              r="13.88"
              transform="translate(-53.03 199.75) rotate(-45)"
            />
            <circle
              class="tru-4"
              cx="214.61"
              cy="163.89"
              r="13"
              transform="translate(-53.03 199.75) rotate(-45)"
            />
            <path
              class="tru-3"
              d="M211.23,175.3l.62-1.49a2.57,2.57,0,0,0-.86-3.07l-1.78-1.29a2.56,2.56,0,0,0-3.18.13l-1.23,1A11.92,11.92,0,0,0,211.23,175.3Z"
            />
            <path
              class="tru-3"
              d="M207,162.57l.67-2.09a2.58,2.58,0,0,0-1.1-3l-1.38-.84a11.87,11.87,0,0,0-2.46,7.24c0,.11,0,.21,0,.32l1.6.13A2.59,2.59,0,0,0,207,162.57Z"
            />
            <path
              class="tru-3"
              d="M224.41,170.63l-1.23-1a2.56,2.56,0,0,0-3.18-.13l-1.78,1.29a2.57,2.57,0,0,0-.86,3.07l.62,1.49A11.92,11.92,0,0,0,224.41,170.63Z"
            />
            <path
              class="tru-3"
              d="M224,156.65l-1.38.84a2.58,2.58,0,0,0-1.1,3l.67,2.09a2.59,2.59,0,0,0,2.66,1.77l1.6-.13c0-.11,0-.21,0-.32A11.87,11.87,0,0,0,224,156.65Z"
            />
            <path
              class="tru-3"
              d="M218.2,154.25l.38-1.57a12,12,0,0,0-8,0l.38,1.57a2.57,2.57,0,0,0,2.5,2h2.19A2.57,2.57,0,0,0,218.2,154.25Z"
            />
            <path
              class="tru-3"
              d="M140.62,128.16a2.2,2.2,0,0,0,2.19,2.2h11.35a2.2,2.2,0,0,0,0-4.39H142.81A2.2,2.2,0,0,0,140.62,128.16Z"
            />
          </svg>
        ),
      },
    },
    {
      name: "4DR Truck",
      icon: {
        rearIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 270 270"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M261.17,145.33V118.84a3.38,3.38,0,0,0-3.39-3.39H182.24l-6.58-21.52a6.26,6.26,0,0,0-6-4.44H121.84A55.7,55.7,0,0,0,89,100.21L70,114.09l-52.57,9.65a6.16,6.16,0,0,0-4.63,3.86h0c0,.06-.05.12-.07.19a1.59,1.59,0,0,0-.07.2,42.83,42.83,0,0,0-1.92,12.57c0,.47,0,.93,0,1.4s0,.67,0,1h-.44A3.31,3.31,0,0,0,7,146.27v3.86a5.87,5.87,0,0,0,5,5.81l10.87,3.68h236a4.19,4.19,0,0,0,4.18-4.17v-8.21A1.91,1.91,0,0,0,261.17,145.33ZM93.65,118.06l-.55-13.88a50.34,50.34,0,0,1,29.74-9.65h11l.93,23.53Z"
            />
            <path
              class="tru4-2"
              d="M134.73,118.06H73.06l20-13.88a50.34,50.34,0,0,1,29.74-9.65h11Z"
            />
            <path
              class="tru4-3"
              d="M12.6,128c0-.13.09-.27.14-.39h8.61a2.7,2.7,0,0,1,2.46,3.84l-4.12,9A2.63,2.63,0,0,1,17.3,142H10.71A42.47,42.47,0,0,1,12.6,128Z"
            />
            <path
              class="tru4-3"
              d="M261.17,122.77h-5.38a1,1,0,0,0-.78.37,1,1,0,0,0-.2.84l2.29,10.39a1,1,0,0,0,.84.78l3.23.28Z"
            />
            <circle
              class="tru4-3"
              cx="49.2"
              cy="158.29"
              r="22.22"
              transform="translate(-33.29 14.51) rotate(-12.58)"
            />
            <circle class="tru4-4" cx="49.2" cy="158.29" r="14.54" />
            <path
              class="tru4-3"
              d="M49.2,155.41a2.88,2.88,0,1,0,2.87,2.88A2.88,2.88,0,0,0,49.2,155.41Z"
            />
            <circle class="tru4-3" cx="49.2" cy="153.11" r="0.85" />
            <path
              class="tru4-3"
              d="M43.46,156.42A.86.86,0,0,0,44,157.5a.85.85,0,0,0,.53-1.62A.86.86,0,0,0,43.46,156.42Z"
            />
            <path
              class="tru4-3"
              d="M45.65,163.16a.86.86,0,0,0,1-1.38.86.86,0,0,0-1.19.19A.85.85,0,0,0,45.65,163.16Z"
            />
            <path
              class="tru4-3"
              d="M52.74,163.16a.85.85,0,0,0,.19-1.19.86.86,0,0,0-1.19-.19.85.85,0,0,0-.19,1.19A.84.84,0,0,0,52.74,163.16Z"
            />
            <path
              class="tru4-3"
              d="M54.93,156.42a.85.85,0,0,0-1.62.53.85.85,0,1,0,1.62-.53Z"
            />
            <path
              class="tru4-3"
              d="M45.6,170.44l.66-1.59a2.73,2.73,0,0,0-.92-3.27l-1.89-1.37a2.75,2.75,0,0,0-3.4.13l-1.3,1.12A12.62,12.62,0,0,0,45.6,170.44Z"
            />
            <path
              class="tru4-3"
              d="M41.07,156.87l.72-2.22a2.75,2.75,0,0,0-1.18-3.19l-1.47-.89a12.62,12.62,0,0,0-2.62,7.72c0,.11,0,.22,0,.33l1.71.14A2.75,2.75,0,0,0,41.07,156.87Z"
            />
            <path
              class="tru4-3"
              d="M59.65,165.46l-1.31-1.12a2.75,2.75,0,0,0-3.4-.13l-1.89,1.37a2.76,2.76,0,0,0-.92,3.27l.66,1.59A12.68,12.68,0,0,0,59.65,165.46Z"
            />
            <path
              class="tru4-3"
              d="M59.25,150.57l-1.47.89a2.73,2.73,0,0,0-1.17,3.19l.72,2.22a2.74,2.74,0,0,0,2.82,1.89l1.72-.14c0-.11,0-.22,0-.33A12.56,12.56,0,0,0,59.25,150.57Z"
            />
            <path
              class="tru4-3"
              d="M53,148l.4-1.68a12.82,12.82,0,0,0-8.47,0l.4,1.68A2.75,2.75,0,0,0,48,150.12h2.34A2.74,2.74,0,0,0,53,148Z"
            />
            <circle
              class="tru4-3"
              cx="215.4"
              cy="158.29"
              r="22.22"
              transform="translate(-29.3 50.7) rotate(-12.58)"
            />
            <circle class="tru4-4" cx="215.4" cy="158.29" r="14.54" />
            <path
              class="tru4-3"
              d="M215.4,155.41a2.88,2.88,0,1,0,2.88,2.88A2.88,2.88,0,0,0,215.4,155.41Z"
            />
            <circle class="tru4-3" cx="215.4" cy="153.11" r="0.85" />
            <path
              class="tru4-3"
              d="M209.67,156.42a.86.86,0,0,0,.55,1.08.85.85,0,1,0-.55-1.08Z"
            />
            <path
              class="tru4-3"
              d="M211.86,163.16a.84.84,0,0,0,1.19-.19.85.85,0,0,0-.19-1.19.85.85,0,1,0-1,1.38Z"
            />
            <path
              class="tru4-3"
              d="M219,163.16a.85.85,0,1,0-1.19-.19A.84.84,0,0,0,219,163.16Z"
            />
            <path
              class="tru4-3"
              d="M221.14,156.42a.86.86,0,0,0-1.08-.54.85.85,0,0,0,.53,1.62A.86.86,0,0,0,221.14,156.42Z"
            />
            <path
              class="tru4-3"
              d="M211.81,170.44l.66-1.59a2.73,2.73,0,0,0-.92-3.27l-1.89-1.37a2.75,2.75,0,0,0-3.4.13L205,165.46A12.68,12.68,0,0,0,211.81,170.44Z"
            />
            <path
              class="tru4-3"
              d="M207.27,156.87l.73-2.22a2.75,2.75,0,0,0-1.18-3.19l-1.47-.89a12.62,12.62,0,0,0-2.62,7.72v.33l1.72.14A2.74,2.74,0,0,0,207.27,156.87Z"
            />
            <path
              class="tru4-3"
              d="M225.86,165.46l-1.31-1.12a2.75,2.75,0,0,0-3.4-.13l-1.89,1.37a2.73,2.73,0,0,0-.92,3.27l.66,1.59A12.68,12.68,0,0,0,225.86,165.46Z"
            />
            <path
              class="tru4-3"
              d="M225.46,150.57l-1.47.89a2.75,2.75,0,0,0-1.18,3.19l.73,2.22a2.74,2.74,0,0,0,2.82,1.89l1.71-.14c0-.11,0-.22,0-.33A12.62,12.62,0,0,0,225.46,150.57Z"
            />
            <path
              class="tru4-3"
              d="M219.24,148l.4-1.68a12.82,12.82,0,0,0-8.47,0l.4,1.68a2.74,2.74,0,0,0,2.67,2.11h2.33A2.74,2.74,0,0,0,219.24,148Z"
            />
            <path
              class="tru4-3"
              d="M138.63,94.53l.94,23.53h34.79a2.51,2.51,0,0,0,2.41-3.25l-5.92-19.4a1.26,1.26,0,0,0-1.19-.88Z"
            />
            <path
              class="tru4-3"
              d="M120.21,127.58a1.89,1.89,0,0,0,1.89,1.89h9.74a1.89,1.89,0,1,0,0-3.77H122.1A1.89,1.89,0,0,0,120.21,127.58Z"
            />
            <path
              class="tru4-3"
              d="M163.37,127.58a1.89,1.89,0,0,0,1.89,1.89H175a1.89,1.89,0,1,0,0-3.77h-9.74A1.89,1.89,0,0,0,163.37,127.58Z"
            />
          </svg>
        ),
        fullIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 270 270"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M261.17,145.33V118.84a3.38,3.38,0,0,0-3.39-3.39H182.24l-6.58-21.52a6.26,6.26,0,0,0-6-4.44H121.84A55.7,55.7,0,0,0,89,100.21L70,114.09l-52.57,9.65a6.16,6.16,0,0,0-4.63,3.86h0c0,.06-.05.12-.07.19a1.59,1.59,0,0,0-.07.2,42.83,42.83,0,0,0-1.92,12.57c0,.47,0,.93,0,1.4s0,.67,0,1h-.44A3.31,3.31,0,0,0,7,146.27v3.86a5.87,5.87,0,0,0,5,5.81l10.87,3.68h236a4.19,4.19,0,0,0,4.18-4.17v-8.21A1.91,1.91,0,0,0,261.17,145.33ZM93.65,118.06l-.55-13.88a50.34,50.34,0,0,1,29.74-9.65h11l.93,23.53Z"
            />
            <path
              class="tru4-3"
              d="M134.73,118.06H73.06l20-13.88a50.34,50.34,0,0,1,29.74-9.65h11Z"
            />
            <path
              class="tru4-3"
              d="M12.6,128c0-.13.09-.26.14-.39h8.61a2.7,2.7,0,0,1,2.46,3.84l-4.12,9A2.63,2.63,0,0,1,17.3,142H10.71A42.51,42.51,0,0,1,12.6,128Z"
            />
            <path
              class="tru4-3"
              d="M261.17,122.77h-5.38a1,1,0,0,0-.78.37,1,1,0,0,0-.2.84l2.29,10.4a1,1,0,0,0,.84.77l3.23.28Z"
            />
            <circle
              class="tru4-3"
              cx="49.2"
              cy="158.29"
              r="22.22"
              transform="translate(-33.29 14.51) rotate(-12.58)"
            />
            <circle class="tru4-3" cx="49.2" cy="158.29" r="14.54" />
            <path
              class="tru4-3"
              d="M49.2,155.41a2.88,2.88,0,1,0,2.87,2.88A2.88,2.88,0,0,0,49.2,155.41Z"
            />
            <circle class="tru4-3" cx="49.2" cy="153.11" r="0.85" />
            <path
              class="tru4-3"
              d="M43.46,156.42A.86.86,0,0,0,44,157.5a.85.85,0,0,0,.53-1.62A.86.86,0,0,0,43.46,156.42Z"
            />
            <path
              class="tru4-3"
              d="M45.65,163.17a.86.86,0,0,0,1.19-.19.85.85,0,0,0-1.37-1A.86.86,0,0,0,45.65,163.17Z"
            />
            <path
              class="tru4-3"
              d="M52.74,163.17a.86.86,0,0,0,.19-1.2.86.86,0,0,0-1.38,1A.86.86,0,0,0,52.74,163.17Z"
            />
            <path
              class="tru4-3"
              d="M54.93,156.42a.85.85,0,0,0-1.62.53.85.85,0,1,0,1.62-.53Z"
            />
            <path
              class="tru4-3"
              d="M45.6,170.45l.66-1.59a2.74,2.74,0,0,0-.92-3.28l-1.89-1.37a2.74,2.74,0,0,0-3.4.14l-1.3,1.12A12.72,12.72,0,0,0,45.6,170.45Z"
            />
            <path
              class="tru4-3"
              d="M41.07,156.87l.72-2.22a2.76,2.76,0,0,0-1.18-3.19l-1.47-.89a12.64,12.64,0,0,0-2.62,7.72c0,.11,0,.22,0,.33l1.71.14A2.75,2.75,0,0,0,41.07,156.87Z"
            />
            <path
              class="tru4-3"
              d="M59.65,165.47l-1.31-1.12a2.74,2.74,0,0,0-3.4-.14l-1.89,1.37a2.77,2.77,0,0,0-.92,3.28l.66,1.59A12.78,12.78,0,0,0,59.65,165.47Z"
            />
            <path
              class="tru4-3"
              d="M59.25,150.57l-1.47.89a2.74,2.74,0,0,0-1.17,3.19l.72,2.22a2.74,2.74,0,0,0,2.82,1.89l1.72-.14c0-.11,0-.22,0-.33A12.58,12.58,0,0,0,59.25,150.57Z"
            />
            <path
              class="tru4-3"
              d="M53,148l.4-1.67a12.65,12.65,0,0,0-8.47,0l.4,1.67A2.75,2.75,0,0,0,48,150.12h2.34A2.74,2.74,0,0,0,53,148Z"
            />
            <circle
              class="tru4-3"
              cx="215.4"
              cy="158.29"
              r="22.22"
              transform="translate(-29.3 50.7) rotate(-12.58)"
            />
            <circle class="tru4-3" cx="215.4" cy="158.29" r="14.54" />
            <path
              class="tru4-3"
              d="M215.4,155.41a2.88,2.88,0,1,0,2.88,2.88A2.88,2.88,0,0,0,215.4,155.41Z"
            />
            <circle class="tru4-3" cx="215.4" cy="153.11" r="0.85" />
            <path
              class="tru4-3"
              d="M209.67,156.42a.86.86,0,0,0,.55,1.08.85.85,0,1,0-.55-1.08Z"
            />
            <path
              class="tru4-3"
              d="M211.86,163.17a.86.86,0,0,0,1.19-.19.86.86,0,0,0-1.38-1A.87.87,0,0,0,211.86,163.17Z"
            />
            <path
              class="tru4-3"
              d="M219,163.17a.87.87,0,0,0,.19-1.2.86.86,0,0,0-1.38,1A.86.86,0,0,0,219,163.17Z"
            />
            <path
              class="tru4-3"
              d="M221.14,156.42a.86.86,0,0,0-1.08-.54.85.85,0,0,0,.53,1.62A.86.86,0,0,0,221.14,156.42Z"
            />
            <path
              class="tru4-3"
              d="M211.81,170.45l.66-1.59a2.74,2.74,0,0,0-.92-3.28l-1.89-1.37a2.74,2.74,0,0,0-3.4.14L205,165.47A12.78,12.78,0,0,0,211.81,170.45Z"
            />
            <path
              class="tru4-3"
              d="M207.27,156.87l.73-2.22a2.76,2.76,0,0,0-1.18-3.19l-1.47-.89a12.64,12.64,0,0,0-2.62,7.72v.33l1.72.14A2.74,2.74,0,0,0,207.27,156.87Z"
            />
            <path
              class="tru4-3"
              d="M225.86,165.47l-1.31-1.12a2.74,2.74,0,0,0-3.4-.14l-1.89,1.37a2.74,2.74,0,0,0-.92,3.28l.66,1.59A12.78,12.78,0,0,0,225.86,165.47Z"
            />
            <path
              class="tru4-3"
              d="M225.46,150.57l-1.47.89a2.76,2.76,0,0,0-1.18,3.19l.73,2.22a2.74,2.74,0,0,0,2.82,1.89l1.71-.14c0-.11,0-.22,0-.33A12.64,12.64,0,0,0,225.46,150.57Z"
            />
            <path
              class="tru4-3"
              d="M219.24,148l.4-1.67a12.65,12.65,0,0,0-8.47,0l.4,1.67a2.74,2.74,0,0,0,2.67,2.11h2.33A2.74,2.74,0,0,0,219.24,148Z"
            />
            <path
              class="tru4-3"
              d="M138.63,94.54l.94,23.52h34.79a2.51,2.51,0,0,0,2.41-3.25l-5.92-19.4a1.25,1.25,0,0,0-1.19-.87Z"
            />
            <path
              class="tru4-3"
              d="M120.21,127.58a1.89,1.89,0,0,0,1.89,1.89h9.74a1.89,1.89,0,1,0,0-3.77H122.1A1.89,1.89,0,0,0,120.21,127.58Z"
            />
            <path
              class="tru4-3"
              d="M163.37,127.58a1.89,1.89,0,0,0,1.89,1.89H175a1.89,1.89,0,1,0,0-3.77h-9.74A1.89,1.89,0,0,0,163.37,127.58Z"
            />
          </svg>
        ),
      },
    },
    {
      name: "Station Wagon",
      icon: {
        rearIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 270 270"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M262.8,144.32,259,136.74V121.2a2,2,0,0,0-.48-1.29l-24-28a2.06,2.06,0,0,0-1.36-.7c-.38,0-22.43-1.8-48-2.32,0,0-.1,0-.15,0l-.13,0c-14.58-.3-30.27-.16-43.75.92a2,2,0,0,0-.65.06c-2.85.24-5.61.51-8.23.84-32.87,4.18-52.48,21.16-56.08,24.53-23.82,1-55.28,7.94-55.6,8a2,2,0,0,0-1.17.75l-3.91,5.22h0l-6,8h0L7.4,140a2,2,0,0,0-.4,1.2v16a2,2,0,0,0,1.76,2l28.13,3.52,3.87.48H228.68c21.14-.19,28.49-2,28.81-2.06a2,2,0,0,0,1.44-1.39l4-14A2,2,0,0,0,262.8,144.32Zm-128.51-29.1H82.43A103.84,103.84,0,0,1,132.75,94.7c1.57-.2,3.19-.38,4.84-.54Z"
            />
            <path
              class="sw-2"
              d="M137.59,94.16l-3.3,21.06H82.43A103.84,103.84,0,0,1,132.75,94.7C134.32,94.5,135.94,94.32,137.59,94.16Z"
            />
            <path
              class="sw-3"
              d="M172,124.21a2,2,0,0,1,2-2h8a2,2,0,0,1,0,4h-8A2,2,0,0,1,172,124.21Z"
            />
            <path
              class="sw-3"
              d="M120,124.21a2,2,0,0,1,2-2h8a2,2,0,0,1,0,4h-8A2,2,0,0,1,120,124.21Z"
            />
            <path
              class="sw-3"
              d="M9.48,137.23H23.06l0,0a8,8,0,0,0,8-8l0,0H15.48Z"
            />
            <path
              class="sw-3"
              d="M249.59,134.73a2,2,0,0,0,2,2H259V121.2h-4.55a2,2,0,0,0-2,2l-2.8,11h0A2,2,0,0,0,249.59,134.73Z"
            />
            <path
              class="sw-3"
              d="M189,92.93v22.28h34c1.05,0,2.86-.14,3.25-1,1.27-3-5.66-12.87-13-20.53C207.11,93.35,196.22,93.09,189,92.93Z"
            />
            <path
              class="sw-3"
              d="M183,92.84v22.37H140.35l3.38-21.51C155.9,92.77,169.82,92.63,183,92.84Z"
            />
            <path
              class="sw-3"
              d="M204.62,181.12a22,22,0,1,0-19.53-24.3A22,22,0,0,0,204.62,181.12Z"
            />
            <path
              class="sw-4"
              d="M190.6,155.36l9.19,2.46,1.33.36a5.74,5.74,0,0,1,.66-1.89l-1.25-.55-8.7-3.86A15.8,15.8,0,0,0,190.6,155.36Z"
            />
            <path
              class="sw-4"
              d="M194.75,170.76a15.77,15.77,0,0,0,2.81,2.4l5.59-7.7.81-1.11a5.67,5.67,0,0,1-1.54-1.3l-1,1Z"
            />
            <path
              class="sw-4"
              d="M212.46,156.76a5.5,5.5,0,0,1,.5,1.93l1.35-.24,9.37-1.67a16.32,16.32,0,0,0-.91-3.56l-9,3.09Z"
            />
            <path
              class="sw-4"
              d="M210,164.4l.79,1.12,5.44,7.81,2.85-2.34-6.58-6.88-.95-1A5.47,5.47,0,0,1,210,164.4Z"
            />
            <path
              class="sw-4"
              d="M206.65,153.24a4.79,4.79,0,0,1,1,0,5.16,5.16,0,0,1,1,.2l.26-1.36,1.76-9.35-3.66-.4-.3,9.51Z"
            />
            <path
              class="sw-4"
              d="M209,159.44a.43.43,0,0,0,.35.2h0a.45.45,0,0,0,.43-.34l0-.11a.45.45,0,0,0-.45-.45.46.46,0,0,0-.46.45A.57.57,0,0,0,209,159.44Z"
            />
            <path
              class="sw-4"
              d="M209,157.9a.44.44,0,0,0,.13-.31.41.41,0,0,0-.12-.32.54.54,0,0,0-.33-.14.42.42,0,0,0-.31.13.44.44,0,0,0,0,.63.44.44,0,0,0,.33.14A.49.49,0,0,0,209,157.9Z"
            />
            <path
              class="sw-4"
              d="M208.32,160.54a.45.45,0,0,0,.31.77.43.43,0,0,0,.31-.12.45.45,0,0,0,.14-.32.44.44,0,0,0-.13-.32.43.43,0,0,0-.32-.14A.44.44,0,0,0,208.32,160.54Z"
            />
            <path
              class="sw-4"
              d="M207.44,156.88a.45.45,0,1,0-.45.45A.45.45,0,0,0,207.44,156.88Z"
            />
            <path
              class="sw-4"
              d="M206.68,161.23a.48.48,0,0,0-.11.29.44.44,0,0,0,.32.43.31.31,0,0,0,.13,0h.06a.45.45,0,0,0,.39-.45.43.43,0,0,0-.14-.32.44.44,0,0,0-.65,0Z"
            />
            <path
              class="sw-4"
              d="M206,159.1s0,.07,0,.1a1.11,1.11,0,0,0,0,.19.42.42,0,0,0,0,.12.87.87,0,0,0,.16.3.93.93,0,0,0,.44.31,1.36,1.36,0,0,0,.29.06H207l.21,0a1,1,0,0,0,.56-.39,1,1,0,0,0,.1-.17l.06-.18s0-.07,0-.1,0-.07,0-.11a1,1,0,0,0-.23-.62l-.06-.07-.1-.08a.94.94,0,0,0-.26-.14.88.88,0,0,0-.33-.06.67.67,0,0,0-.24,0,.81.81,0,0,0-.27.11l-.16.13A1,1,0,0,0,206,159.1Z"
            />
            <path
              class="sw-4"
              d="M205,160.51a.44.44,0,0,0-.13.31.41.41,0,0,0,.12.32.47.47,0,0,0,.33.14.4.4,0,0,0,.31-.13.44.44,0,0,0,0-.63.43.43,0,0,0-.32-.14A.44.44,0,0,0,205,160.51Z"
            />
            <path
              class="sw-4"
              d="M205.69,157.87a.48.48,0,0,0,0-.64.46.46,0,0,0-.33-.13.43.43,0,0,0-.31.12.45.45,0,0,0,.31.78h0A.43.43,0,0,0,205.69,157.87Z"
            />
            <path
              class="sw-4"
              d="M204.23,159.22a.45.45,0,0,0,.35.44h.1a.44.44,0,0,0,.45-.44.46.46,0,0,0-.45-.46.44.44,0,0,0-.39.24A.42.42,0,0,0,204.23,159.22Z"
            />
            <path
              class="sw-4"
              d="M221.37,150.39l-8.08,3.72a8.05,8.05,0,0,0-2.16-1.87l2.52-8.53A16.88,16.88,0,0,1,221.37,150.39Z"
            />
            <path
              class="sw-4"
              d="M221,168.54l-6.77-5.76a8,8,0,0,0,.71-2.18l8.86-.67c0,.37,0,.74-.08,1.1A16.83,16.83,0,0,1,221,168.54Z"
            />
            <path
              class="sw-4"
              d="M190.16,158.47l8.78,1.43a8,8,0,0,0,.73,2.73l-6.88,5.64A16.86,16.86,0,0,1,190.16,158.47Z"
            />
            <path
              class="sw-4"
              d="M209.09,167l4.33,7.77a16.92,16.92,0,0,1-8.24,1.17,17.36,17.36,0,0,1-4.86-1.28l4.47-7.7a7.74,7.74,0,0,0,1.34.26A8,8,0,0,0,209.09,167Z"
            />
            <path
              class="sw-4"
              d="M203.85,142.64l.62,8.87a8.31,8.31,0,0,0-3.3,2.08l-7.72-4.41A16.86,16.86,0,0,1,203.85,142.64Z"
            />
            <path
              class="sw-3"
              d="M61,181.12a22,22,0,1,1,19.53-24.3A22,22,0,0,1,61,181.12Z"
            />
            <path
              class="sw-4"
              d="M75.06,155.36l-9.19,2.46-1.33.36a5.74,5.74,0,0,0-.66-1.89l1.25-.55,8.7-3.86A15.8,15.8,0,0,1,75.06,155.36Z"
            />
            <path
              class="sw-4"
              d="M70.91,170.76a15.77,15.77,0,0,1-2.81,2.4l-5.59-7.7-.81-1.11a5.67,5.67,0,0,0,1.54-1.3l1,1Z"
            />
            <path
              class="sw-4"
              d="M53.2,156.76a5.5,5.5,0,0,0-.5,1.93l-1.35-.24L42,156.78a16.32,16.32,0,0,1,.91-3.56l9,3.09Z"
            />
            <path
              class="sw-4"
              d="M55.69,164.4l-.79,1.12-5.44,7.81L46.61,171l6.58-6.88,1-1A5.47,5.47,0,0,0,55.69,164.4Z"
            />
            <path
              class="sw-4"
              d="M59,153.24a4.79,4.79,0,0,0-1,0,5.16,5.16,0,0,0-1,.2l-.26-1.36L55,142.75l3.66-.4.3,9.51Z"
            />
            <path
              class="sw-4"
              d="M56.7,159.44a.43.43,0,0,1-.35.2h0a.45.45,0,0,1-.43-.34l0-.11a.45.45,0,0,1,.45-.45.46.46,0,0,1,.46.45A.57.57,0,0,1,56.7,159.44Z"
            />
            <path
              class="sw-4"
              d="M56.68,157.9a.44.44,0,0,1-.13-.31.41.41,0,0,1,.12-.32.54.54,0,0,1,.33-.14.42.42,0,0,1,.31.13.44.44,0,0,1,0,.63A.44.44,0,0,1,57,158,.49.49,0,0,1,56.68,157.9Z"
            />
            <path
              class="sw-4"
              d="M57.34,160.54a.45.45,0,0,1-.31.77.43.43,0,0,1-.31-.12.45.45,0,0,1-.14-.32.44.44,0,0,1,.13-.32.43.43,0,0,1,.32-.14A.44.44,0,0,1,57.34,160.54Z"
            />
            <path
              class="sw-4"
              d="M58.22,156.88a.45.45,0,1,1,.45.45A.45.45,0,0,1,58.22,156.88Z"
            />
            <path
              class="sw-4"
              d="M59,161.23a.48.48,0,0,1,.11.29.44.44,0,0,1-.32.43.31.31,0,0,1-.13,0h-.06a.45.45,0,0,1-.39-.45.43.43,0,0,1,.14-.32.44.44,0,0,1,.65,0Z"
            />
            <path
              class="sw-4"
              d="M59.62,159.1s0,.07,0,.1a1.11,1.11,0,0,1,0,.19.42.42,0,0,1,0,.12.87.87,0,0,1-.16.3.93.93,0,0,1-.44.31,1.36,1.36,0,0,1-.29.06h0l-.21,0a1,1,0,0,1-.56-.39,1,1,0,0,1-.1-.17l-.06-.18s0-.07,0-.1,0-.07,0-.11a1,1,0,0,1,.23-.62l.06-.07.1-.08a.94.94,0,0,1,.26-.14.88.88,0,0,1,.33-.06.67.67,0,0,1,.24,0,.81.81,0,0,1,.27.11l.16.13A1,1,0,0,1,59.62,159.1Z"
            />
            <path
              class="sw-4"
              d="M60.63,160.51a.44.44,0,0,1,.13.31.41.41,0,0,1-.12.32.47.47,0,0,1-.33.14.4.4,0,0,1-.31-.13.44.44,0,0,1,0-.63.43.43,0,0,1,.32-.14A.44.44,0,0,1,60.63,160.51Z"
            />
            <path
              class="sw-4"
              d="M60,157.87a.48.48,0,0,1,0-.64.46.46,0,0,1,.33-.13.43.43,0,0,1,.31.12.45.45,0,0,1-.31.78h0A.43.43,0,0,1,60,157.87Z"
            />
            <path
              class="sw-4"
              d="M61.43,159.22a.45.45,0,0,1-.35.44H61a.44.44,0,0,1-.45-.44.46.46,0,0,1,.45-.46.44.44,0,0,1,.39.24A.42.42,0,0,1,61.43,159.22Z"
            />
            <path
              class="sw-4"
              d="M44.28,150.39l8.08,3.72a8.21,8.21,0,0,1,2.16-1.87L52,143.71A16.9,16.9,0,0,0,44.28,150.39Z"
            />
            <path
              class="sw-4"
              d="M44.62,168.54l6.77-5.76a8,8,0,0,1-.71-2.18l-8.87-.67c0,.37,0,.74.08,1.1A16.73,16.73,0,0,0,44.62,168.54Z"
            />
            <path
              class="sw-4"
              d="M75.49,158.47l-8.77,1.43a8,8,0,0,1-.73,2.73l6.87,5.64A16.86,16.86,0,0,0,75.49,158.47Z"
            />
            <path
              class="sw-4"
              d="M56.56,167l-4.32,7.77A16.9,16.9,0,0,0,60.48,176a17.2,17.2,0,0,0,4.85-1.28L60.86,167a7.45,7.45,0,0,1-1.33.26A8,8,0,0,1,56.56,167Z"
            />
            <path
              class="sw-4"
              d="M61.81,142.64l-.62,8.87a8.21,8.21,0,0,1,3.29,2.08l7.73-4.41A16.91,16.91,0,0,0,61.81,142.64Z"
            />
          </svg>
        ),
        fullIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 270 270"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M262.8,144.32,259,136.74V121.2a2,2,0,0,0-.48-1.29l-24-28a2.06,2.06,0,0,0-1.36-.7c-.38,0-22.43-1.8-48-2.32,0,0-.1,0-.15,0l-.13,0c-14.58-.3-30.27-.16-43.75.92a2,2,0,0,0-.65.06c-2.85.24-5.61.51-8.23.84-32.87,4.18-52.48,21.16-56.08,24.53-23.82,1-55.28,7.94-55.6,8a2,2,0,0,0-1.17.75l-3.91,5.22h0l-6,8h0L7.4,140a2,2,0,0,0-.4,1.2v16a2,2,0,0,0,1.76,2l28.13,3.52,3.87.48H228.68c21.14-.19,28.49-2,28.81-2.06a2,2,0,0,0,1.44-1.39l4-14A2,2,0,0,0,262.8,144.32Zm-128.51-29.1H82.43A103.84,103.84,0,0,1,132.75,94.7c1.57-.2,3.19-.38,4.84-.54Z"
            />
            <path
              class="sw-3"
              d="M137.59,94.16l-3.3,21.06H82.43A103.84,103.84,0,0,1,132.75,94.7C134.32,94.5,135.94,94.32,137.59,94.16Z"
            />
            <path
              class="sw-3"
              d="M172,124.21a2,2,0,0,1,2-2h8a2,2,0,0,1,0,4h-8A2,2,0,0,1,172,124.21Z"
            />
            <path
              class="sw-3"
              d="M120,124.21a2,2,0,0,1,2-2h8a2,2,0,0,1,0,4h-8A2,2,0,0,1,120,124.21Z"
            />
            <path
              class="sw-3"
              d="M9.48,137.23H23.06l0,0a8,8,0,0,0,8-8l0,0H15.48Z"
            />
            <path
              class="sw-3"
              d="M249.59,134.73a2,2,0,0,0,2,2H259V121.2h-4.55a2,2,0,0,0-2,2l-2.8,11h0A2,2,0,0,0,249.59,134.73Z"
            />
            <path
              class="sw-3"
              d="M189,92.93v22.28h34c1.05,0,2.86-.14,3.25-1,1.27-3-5.66-12.87-13-20.53C207.11,93.35,196.22,93.09,189,92.93Z"
            />
            <path
              class="sw-3"
              d="M183,92.84v22.37H140.35l3.38-21.51C155.9,92.77,169.82,92.63,183,92.84Z"
            />
            <path
              class="sw-3"
              d="M204.62,181.12a22,22,0,1,0-19.53-24.3A22,22,0,0,0,204.62,181.12Z"
            />
            <path
              class="sw-3"
              d="M190.6,155.36l9.19,2.46,1.33.36a5.74,5.74,0,0,1,.66-1.89l-1.25-.55-8.7-3.86A15.8,15.8,0,0,0,190.6,155.36Z"
            />
            <path
              class="sw-3"
              d="M194.75,170.76a15.77,15.77,0,0,0,2.81,2.4l5.59-7.7.81-1.11a5.67,5.67,0,0,1-1.54-1.3l-1,1Z"
            />
            <path
              class="sw-3"
              d="M212.46,156.76a5.5,5.5,0,0,1,.5,1.93l1.35-.24,9.37-1.67a16.32,16.32,0,0,0-.91-3.56l-9,3.09Z"
            />
            <path
              class="sw-3"
              d="M210,164.4l.79,1.12,5.44,7.81,2.85-2.34-6.58-6.88-.95-1A5.47,5.47,0,0,1,210,164.4Z"
            />
            <path
              class="sw-3"
              d="M206.65,153.24a4.79,4.79,0,0,1,1,0,5.16,5.16,0,0,1,1,.2l.26-1.36,1.76-9.35-3.66-.4-.3,9.51Z"
            />
            <path
              class="sw-3"
              d="M209,159.44a.43.43,0,0,0,.35.2h0a.45.45,0,0,0,.43-.34l0-.11a.45.45,0,0,0-.45-.45.46.46,0,0,0-.46.45A.57.57,0,0,0,209,159.44Z"
            />
            <path
              class="sw-3"
              d="M209,157.9a.44.44,0,0,0,.13-.31.41.41,0,0,0-.12-.32.54.54,0,0,0-.33-.14.42.42,0,0,0-.31.13.44.44,0,0,0,0,.63.44.44,0,0,0,.33.14A.49.49,0,0,0,209,157.9Z"
            />
            <path
              class="sw-3"
              d="M208.32,160.54a.45.45,0,0,0,.31.77.43.43,0,0,0,.31-.12.45.45,0,0,0,.14-.32.44.44,0,0,0-.13-.32.43.43,0,0,0-.32-.14A.44.44,0,0,0,208.32,160.54Z"
            />
            <path
              class="sw-3"
              d="M207.44,156.88a.45.45,0,1,0-.45.45A.45.45,0,0,0,207.44,156.88Z"
            />
            <path
              class="sw-3"
              d="M206.68,161.23a.48.48,0,0,0-.11.29.44.44,0,0,0,.32.43.31.31,0,0,0,.13,0h.06a.45.45,0,0,0,.39-.45.43.43,0,0,0-.14-.32.44.44,0,0,0-.65,0Z"
            />
            <path
              class="sw-3"
              d="M206,159.1s0,.07,0,.1a1.11,1.11,0,0,0,0,.19.42.42,0,0,0,0,.12.87.87,0,0,0,.16.3.93.93,0,0,0,.44.31,1.36,1.36,0,0,0,.29.06H207l.21,0a1,1,0,0,0,.56-.39,1,1,0,0,0,.1-.17l.06-.18s0-.07,0-.1,0-.07,0-.11a1,1,0,0,0-.23-.62l-.06-.07-.1-.08a.94.94,0,0,0-.26-.14.88.88,0,0,0-.33-.06.67.67,0,0,0-.24,0,.81.81,0,0,0-.27.11l-.16.13A1,1,0,0,0,206,159.1Z"
            />
            <path
              class="sw-3"
              d="M205,160.51a.44.44,0,0,0-.13.31.41.41,0,0,0,.12.32.47.47,0,0,0,.33.14.4.4,0,0,0,.31-.13.44.44,0,0,0,0-.63.43.43,0,0,0-.32-.14A.44.44,0,0,0,205,160.51Z"
            />
            <path
              class="sw-3"
              d="M205.69,157.87a.48.48,0,0,0,0-.64.46.46,0,0,0-.33-.13.43.43,0,0,0-.31.12.45.45,0,0,0,.31.78h0A.43.43,0,0,0,205.69,157.87Z"
            />
            <path
              class="sw-3"
              d="M204.23,159.22a.45.45,0,0,0,.35.44h.1a.44.44,0,0,0,.45-.44.46.46,0,0,0-.45-.46.44.44,0,0,0-.39.24A.42.42,0,0,0,204.23,159.22Z"
            />
            <path
              class="sw-3"
              d="M221.37,150.39l-8.08,3.72a8.05,8.05,0,0,0-2.16-1.87l2.52-8.54A16.9,16.9,0,0,1,221.37,150.39Z"
            />
            <path
              class="sw-3"
              d="M221,168.54l-6.77-5.77a8,8,0,0,0,.71-2.17l8.86-.67c0,.37,0,.74-.08,1.1A16.83,16.83,0,0,1,221,168.54Z"
            />
            <path
              class="sw-3"
              d="M190.16,158.47l8.78,1.43a8.07,8.07,0,0,0,.73,2.73l-6.88,5.64A16.86,16.86,0,0,1,190.16,158.47Z"
            />
            <path
              class="sw-3"
              d="M209.09,167l4.33,7.77a16.8,16.8,0,0,1-8.24,1.16,17,17,0,0,1-4.86-1.27l4.47-7.7a7.74,7.74,0,0,0,1.34.26A8,8,0,0,0,209.09,167Z"
            />
            <path
              class="sw-3"
              d="M203.85,142.64l.62,8.87a8.31,8.31,0,0,0-3.3,2.08l-7.72-4.41A16.86,16.86,0,0,1,203.85,142.64Z"
            />
            <path
              class="sw-3"
              d="M61,181.12a22,22,0,1,1,19.53-24.3A22,22,0,0,1,61,181.12Z"
            />
            <path
              class="sw-3"
              d="M75.06,155.36l-9.19,2.46-1.33.36a5.74,5.74,0,0,0-.66-1.89l1.25-.55,8.7-3.86A15.8,15.8,0,0,1,75.06,155.36Z"
            />
            <path
              class="sw-3"
              d="M70.91,170.76a15.77,15.77,0,0,1-2.81,2.4l-5.59-7.7-.81-1.11a5.67,5.67,0,0,0,1.54-1.3l1,1Z"
            />
            <path
              class="sw-3"
              d="M53.2,156.76a5.5,5.5,0,0,0-.5,1.93l-1.35-.24L42,156.78a16.32,16.32,0,0,1,.91-3.56l9,3.09Z"
            />
            <path
              class="sw-3"
              d="M55.69,164.4l-.79,1.12-5.44,7.81L46.61,171l6.58-6.88,1-1A5.47,5.47,0,0,0,55.69,164.4Z"
            />
            <path
              class="sw-3"
              d="M59,153.24a4.79,4.79,0,0,0-1,0,5.16,5.16,0,0,0-1,.2l-.26-1.36L55,142.75l3.66-.4.3,9.51Z"
            />
            <path
              class="sw-3"
              d="M56.7,159.44a.43.43,0,0,1-.35.2h0a.45.45,0,0,1-.43-.34l0-.11a.45.45,0,0,1,.45-.45.46.46,0,0,1,.46.45A.57.57,0,0,1,56.7,159.44Z"
            />
            <path
              class="sw-3"
              d="M56.68,157.9a.44.44,0,0,1-.13-.31.41.41,0,0,1,.12-.32.54.54,0,0,1,.33-.14.42.42,0,0,1,.31.13.44.44,0,0,1,0,.63A.44.44,0,0,1,57,158,.49.49,0,0,1,56.68,157.9Z"
            />
            <path
              class="sw-3"
              d="M57.34,160.54a.45.45,0,0,1-.31.77.43.43,0,0,1-.31-.12.45.45,0,0,1-.14-.32.44.44,0,0,1,.13-.32.43.43,0,0,1,.32-.14A.44.44,0,0,1,57.34,160.54Z"
            />
            <path
              class="sw-3"
              d="M58.22,156.88a.45.45,0,1,1,.45.45A.45.45,0,0,1,58.22,156.88Z"
            />
            <path
              class="sw-3"
              d="M59,161.23a.48.48,0,0,1,.11.29.44.44,0,0,1-.32.43.31.31,0,0,1-.13,0h-.06a.45.45,0,0,1-.39-.45.43.43,0,0,1,.14-.32.44.44,0,0,1,.65,0Z"
            />
            <path
              class="sw-3"
              d="M59.62,159.1s0,.07,0,.1a1.11,1.11,0,0,1,0,.19.42.42,0,0,1,0,.12.87.87,0,0,1-.16.3.93.93,0,0,1-.44.31,1.36,1.36,0,0,1-.29.06h0l-.21,0a1,1,0,0,1-.56-.39,1,1,0,0,1-.1-.17l-.06-.18s0-.07,0-.1,0-.07,0-.11a1,1,0,0,1,.23-.62l.06-.07.1-.08a.94.94,0,0,1,.26-.14.88.88,0,0,1,.33-.06.67.67,0,0,1,.24,0,.81.81,0,0,1,.27.11l.16.13A1,1,0,0,1,59.62,159.1Z"
            />
            <path
              class="sw-3"
              d="M60.63,160.51a.44.44,0,0,1,.13.31.41.41,0,0,1-.12.32.47.47,0,0,1-.33.14.4.4,0,0,1-.31-.13.44.44,0,0,1,0-.63.43.43,0,0,1,.32-.14A.44.44,0,0,1,60.63,160.51Z"
            />
            <path
              class="sw-3"
              d="M60,157.87a.48.48,0,0,1,0-.64.46.46,0,0,1,.33-.13.43.43,0,0,1,.31.12.45.45,0,0,1-.31.78h0A.43.43,0,0,1,60,157.87Z"
            />
            <path
              class="sw-3"
              d="M61.43,159.22a.45.45,0,0,1-.35.44H61a.44.44,0,0,1-.45-.44.46.46,0,0,1,.45-.46.44.44,0,0,1,.39.24A.42.42,0,0,1,61.43,159.22Z"
            />
            <path
              class="sw-3"
              d="M44.28,150.39l8.08,3.72a8.21,8.21,0,0,1,2.16-1.87L52,143.7A16.93,16.93,0,0,0,44.28,150.39Z"
            />
            <path
              class="sw-3"
              d="M44.62,168.54l6.77-5.77a8,8,0,0,1-.71-2.17l-8.87-.67c0,.37,0,.74.08,1.1A16.73,16.73,0,0,0,44.62,168.54Z"
            />
            <path
              class="sw-3"
              d="M75.49,158.47l-8.77,1.43a8.07,8.07,0,0,1-.73,2.73l6.87,5.64A16.86,16.86,0,0,0,75.49,158.47Z"
            />
            <path
              class="sw-3"
              d="M56.56,167l-4.32,7.77a17,17,0,0,0,13.09-.11L60.86,167a7.45,7.45,0,0,1-1.33.26A8,8,0,0,1,56.56,167Z"
            />
            <path
              class="sw-3"
              d="M61.81,142.64l-.62,8.87a8.21,8.21,0,0,1,3.29,2.08l7.73-4.41A16.91,16.91,0,0,0,61.81,142.64Z"
            />
          </svg>
        ),
      },
    },
    {
      name: "SUV",
      icon: {
        rearIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 270 270"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M240.43,88a5.28,5.28,0,0,0-3.67-1.48H134.38c-23.2,3.1-43.11,15.69-52.18,22.27a28.09,28.09,0,0,1-12.62,5.09l-47.92,6.74c-5.07,1.75-8.61,5.57-10.94,9.13h0A32.65,32.65,0,0,0,7,137.35v15.56a8.47,8.47,0,0,0,8.47,8.47H251a5.3,5.3,0,0,0,5.17-4.14L263,126.75C261.82,109.85,245.61,93,240.43,88Zm-82,30H99.82V102.66c9.55-5.15,21.64-10.14,34.85-12h23.74Z"
            />
            <path
              class="suv-2"
              d="M158.41,90.71v27.21h-72a3.12,3.12,0,0,1-1.8-5.66l.12-.09c2.7-2,11-7,15.13-9.51,9.55-5.15,21.64-10.14,34.85-12Z"
            />
            <path
              class="suv-3"
              d="M221.31,117.92h29a4.07,4.07,0,0,0,3.58-6c-4.79-8.82-11.82-16.4-15.45-20a4.06,4.06,0,0,0-2.87-1.19H221.31Z"
            />
            <rect
              class="suv-3"
              x="164.77"
              y="90.71"
              width="50.18"
              height="27.21"
            />
            <circle class="suv-3" cx="215.59" cy="161.48" r="22.05" />
            <circle class="suv-3" cx="56.06" cy="161.48" r="22.05" />
            <path
              class="suv-3"
              d="M27.49,129.7v3.68A14.19,14.19,0,0,1,13.3,147.57H7V137.35a32.65,32.65,0,0,1,3.71-7.65Z"
            />
            <path
              class="suv-3"
              d="M215,125.58a1.87,1.87,0,0,1-1.87,1.87h-9.67a1.87,1.87,0,0,1,0-3.74h9.67A1.87,1.87,0,0,1,215,125.58Z"
            />
            <path
              class="suv-3"
              d="M158.41,125.58a1.87,1.87,0,0,1-1.87,1.87h-9.67a1.87,1.87,0,0,1,0-3.74h9.67A1.87,1.87,0,0,1,158.41,125.58Z"
            />
            <path
              class="suv-4"
              d="M215.59,144.67a16.81,16.81,0,1,0,16.81,16.81A16.81,16.81,0,0,0,215.59,144.67Zm13.83,16.81c0,.26,0,.51,0,.77l-8.32.43a.92.92,0,0,1-1-.93v-.54a.94.94,0,0,1,1-.93l8.32.43C229.4,161,229.42,161.22,229.42,161.48Zm-27.67,0c0-.26,0-.51,0-.77l8.32-.43a1,1,0,0,1,1,.93v.54a.93.93,0,0,1-1,.93l-8.32-.43C201.78,162,201.75,161.74,201.75,161.48Zm11.42,2a.77.77,0,0,1,1.07-.17.77.77,0,1,1-1.07.17Zm-.73-2.25a.75.75,0,0,1-.49-1,.77.77,0,0,1,1-.49.79.79,0,0,1,.5,1A.78.78,0,0,1,212.44,161.26Zm1.84.22a1.31,1.31,0,1,1,1.31,1.31A1.31,1.31,0,0,1,214.28,161.48Zm.54-3.06a.77.77,0,1,1,.77.77A.77.77,0,0,1,214.82,158.42Zm2.12,4.92a.77.77,0,1,1-.17,1.07A.77.77,0,0,1,216.94,163.34Zm.84-2.57a.76.76,0,0,1,.49-1,.78.78,0,0,1,1,.49.76.76,0,1,1-1.45.48ZM219,158.5l-.38-.38a.93.93,0,0,1,.05-1.36l6.18-5.58a14,14,0,0,1,1.09,1.09l-5.58,6.19A.94.94,0,0,1,219,158.5Zm-1.16-2.34-.75-8.44a13.84,13.84,0,0,1,7.25,3Zm-1-.16a.93.93,0,0,1-.92,1h-.54a.94.94,0,0,1-.93-1l.43-8.32c.26,0,.51,0,.77,0s.51,0,.76,0Zm-3.39.16-6.5-5.43a13.8,13.8,0,0,1,7.25-3Zm-.83.6a.93.93,0,0,1,.05,1.36l-.38.38a.94.94,0,0,1-1.36,0l-5.58-6.19a13.88,13.88,0,0,1,1.08-1.09Zm-2.29,2.52-8.44.75a13.84,13.84,0,0,1,3-7.24Zm0,4.41-5.44,6.49a13.91,13.91,0,0,1-3-7.24Zm.6.82a.93.93,0,0,1,1.36,0l.38.38a.93.93,0,0,1-.05,1.36l-6.19,5.58a13.76,13.76,0,0,1-1.08-1.08Zm2.52,2.29.75,8.44a13.8,13.8,0,0,1-7.25-3Zm1,.16a.94.94,0,0,1,.93-1h.54a.93.93,0,0,1,.92,1l-.43,8.32c-.25,0-.5,0-.76,0s-.51,0-.77,0Zm3.4-.16,6.5,5.43a13.84,13.84,0,0,1-7.25,3Zm.83-.6a.93.93,0,0,1-.05-1.36l.38-.38a.93.93,0,0,1,1.36,0l5.58,6.19a13.88,13.88,0,0,1-1.09,1.08Zm2.29-2.51,8.44-.75a13.84,13.84,0,0,1-3,7.24Zm0-4.41,5.43-6.49a13.77,13.77,0,0,1,3,7.24Z"
            />
            <path
              class="suv-4"
              d="M56.06,144.67a16.81,16.81,0,1,0,16.82,16.81A16.81,16.81,0,0,0,56.06,144.67ZM69.9,161.48c0,.26,0,.51,0,.77l-8.32.43a.92.92,0,0,1-1-.93v-.54a.94.94,0,0,1,1-.93l8.32.43C69.88,161,69.9,161.22,69.9,161.48Zm-27.67,0c0-.26,0-.51,0-.77l8.32-.43a.94.94,0,0,1,1,.93v.54a.92.92,0,0,1-1,.93l-8.32-.43C42.25,162,42.23,161.74,42.23,161.48Zm11.41,2a.78.78,0,0,1,1.08-.17.77.77,0,0,1-.91,1.24A.78.78,0,0,1,53.64,163.51Zm-.73-2.25a.77.77,0,0,1-.49-1,.78.78,0,0,1,1-.49.77.77,0,1,1-.47,1.46Zm1.85.22a1.31,1.31,0,1,1,1.3,1.31A1.31,1.31,0,0,1,54.76,161.48Zm.54-3.06a.77.77,0,1,1,.76.77A.77.77,0,0,1,55.3,158.42Zm2.11,4.92a.78.78,0,0,1,1.08.17.77.77,0,0,1-1.24.9A.77.77,0,0,1,57.41,163.34Zm.84-2.57a.77.77,0,0,1,.49-1,.79.79,0,0,1,1,.49.77.77,0,1,1-1.46.48Zm1.17-2.27-.38-.38a.93.93,0,0,1,.05-1.36l6.19-5.58a13.88,13.88,0,0,1,1.08,1.09l-5.57,6.19A1,1,0,0,1,59.42,158.5Zm-1.15-2.34-.75-8.44a13.77,13.77,0,0,1,7.24,3Zm-1-.16a.93.93,0,0,1-.93,1h-.54a.93.93,0,0,1-.92-1l.43-8.32.76,0c.26,0,.51,0,.77,0Zm-3.4.16-6.49-5.43a13.77,13.77,0,0,1,7.24-3Zm-.82.6a.93.93,0,0,1,0,1.36l-.38.38a.93.93,0,0,1-1.35,0l-5.58-6.19a13.88,13.88,0,0,1,1.08-1.09Zm-2.3,2.52-8.43.75a13.68,13.68,0,0,1,3-7.24Zm0,4.41-5.43,6.49a13.75,13.75,0,0,1-3-7.24Zm.61.82a.92.92,0,0,1,1.35,0l.38.38a.93.93,0,0,1,0,1.36l-6.19,5.58a13.76,13.76,0,0,1-1.08-1.08Zm2.51,2.29.75,8.44a13.77,13.77,0,0,1-7.24-3Zm1,.16a.93.93,0,0,1,.92-1h.54a.93.93,0,0,1,.93,1l-.43,8.32c-.26,0-.51,0-.77,0s-.51,0-.76,0Zm3.4-.16,6.49,5.43a13.77,13.77,0,0,1-7.24,3Zm.82-.6a.93.93,0,0,1-.05-1.36l.38-.38a.94.94,0,0,1,1.37,0l5.57,6.19a13.76,13.76,0,0,1-1.08,1.08Zm2.3-2.51,8.43-.75a13.75,13.75,0,0,1-3,7.24Zm0-4.41,5.43-6.49a13.68,13.68,0,0,1,3,7.24Z"
            />
          </svg>
        ),
        fullIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 270 270"
          >
            <defs></defs>
            <path
              fill="currentColor"
              stroke="#FFF"
              d="M240.43,88a5.28,5.28,0,0,0-3.67-1.48H134.38c-23.2,3.1-43.11,15.69-52.18,22.27a28.09,28.09,0,0,1-12.62,5.09l-47.92,6.74c-5.07,1.75-8.61,5.57-10.94,9.13h0A32.65,32.65,0,0,0,7,137.35v15.56a8.47,8.47,0,0,0,8.47,8.47H251a5.3,5.3,0,0,0,5.17-4.14L263,126.75C261.82,109.85,245.61,93,240.43,88Zm-82,30H99.82V102.66c9.55-5.15,21.64-10.14,34.85-12h23.74Z"
            />
            <path
              class="suv-3"
              d="M158.41,90.71v27.21h-72a3.12,3.12,0,0,1-1.8-5.66l.12-.09c2.7-2,11-7,15.13-9.51,9.55-5.15,21.64-10.14,34.85-12Z"
            />
            <path
              class="suv-3"
              d="M221.31,117.92h29a4.07,4.07,0,0,0,3.58-6c-4.79-8.82-11.82-16.4-15.45-20a4.06,4.06,0,0,0-2.87-1.19H221.31Z"
            />
            <rect
              class="suv-3"
              x="164.77"
              y="90.71"
              width="50.18"
              height="27.21"
            />
            <circle class="suv-3" cx="215.59" cy="161.48" r="22.05" />
            <circle class="suv-3" cx="56.06" cy="161.48" r="22.05" />
            <path
              class="suv-3"
              d="M27.49,129.7v3.68A14.19,14.19,0,0,1,13.3,147.57H7V137.35a32.65,32.65,0,0,1,3.71-7.65Z"
            />
            <path
              class="suv-3"
              d="M215,125.58a1.87,1.87,0,0,1-1.87,1.87h-9.67a1.87,1.87,0,0,1,0-3.74h9.67A1.87,1.87,0,0,1,215,125.58Z"
            />
            <path
              class="suv-3"
              d="M158.41,125.58a1.87,1.87,0,0,1-1.87,1.87h-9.67a1.87,1.87,0,0,1,0-3.74h9.67A1.87,1.87,0,0,1,158.41,125.58Z"
            />
            <path
              class="suv-3"
              d="M215.59,144.67a16.81,16.81,0,1,0,16.81,16.81A16.82,16.82,0,0,0,215.59,144.67Zm13.83,16.81c0,.26,0,.51,0,.77l-8.32.43a.92.92,0,0,1-1-.93v-.53a.94.94,0,0,1,1-.94l8.32.43C229.4,161,229.42,161.22,229.42,161.48Zm-27.67,0c0-.26,0-.51,0-.77l8.32-.43a1,1,0,0,1,1,.94v.53a.93.93,0,0,1-1,.93l-8.32-.43C201.78,162,201.75,161.74,201.75,161.48Zm11.42,2a.77.77,0,0,1,1.07-.17.77.77,0,1,1-1.07.17Zm-.73-2.25a.74.74,0,0,1-.49-1,.76.76,0,0,1,1-.49.76.76,0,1,1-.47,1.45Zm1.84.22a1.31,1.31,0,1,1,1.31,1.31A1.31,1.31,0,0,1,214.28,161.48Zm.54-3.06a.77.77,0,1,1,.77.77A.77.77,0,0,1,214.82,158.42Zm2.12,4.92a.77.77,0,1,1-.17,1.07A.77.77,0,0,1,216.94,163.34Zm.84-2.57a.74.74,0,0,1,.49-1,.76.76,0,0,1,1,.49.75.75,0,0,1-.49,1A.76.76,0,0,1,217.78,160.77Zm1.17-2.26-.38-.38a.94.94,0,0,1,.05-1.37l6.18-5.58a14,14,0,0,1,1.09,1.09l-5.58,6.19A.94.94,0,0,1,219,158.51Zm-1.16-2.35-.75-8.44a13.84,13.84,0,0,1,7.25,3Zm-1-.16a.93.93,0,0,1-.92,1h-.54a.94.94,0,0,1-.93-1l.43-8.31c.26,0,.51,0,.77,0s.51,0,.76,0Zm-3.39.16-6.5-5.43a13.8,13.8,0,0,1,7.25-3Zm-.83.6a.94.94,0,0,1,.05,1.37l-.38.38a.94.94,0,0,1-1.36,0l-5.58-6.19a13.88,13.88,0,0,1,1.08-1.09Zm-2.29,2.52-8.44.75a13.84,13.84,0,0,1,3-7.24Zm0,4.41-5.44,6.49a13.84,13.84,0,0,1-3-7.24Zm.6.82a.93.93,0,0,1,1.36,0l.38.38a.93.93,0,0,1-.05,1.36l-6.19,5.58a13.76,13.76,0,0,1-1.08-1.08Zm2.52,2.29.75,8.44a13.72,13.72,0,0,1-7.25-3Zm1,.16a.94.94,0,0,1,.93-1h.54a.93.93,0,0,1,.92,1l-.43,8.32c-.25,0-.5,0-.76,0s-.51,0-.77,0Zm3.4-.16,6.5,5.43a13.84,13.84,0,0,1-7.25,3Zm.83-.6a.93.93,0,0,1-.05-1.36l.38-.38a.93.93,0,0,1,1.36,0l5.58,6.19a13.88,13.88,0,0,1-1.09,1.08Zm2.29-2.51,8.44-.75a13.77,13.77,0,0,1-3,7.24Zm0-4.41,5.43-6.49a13.77,13.77,0,0,1,3,7.24Z"
            />
            <path
              class="suv-3"
              d="M56.06,144.67a16.81,16.81,0,1,0,16.82,16.81A16.82,16.82,0,0,0,56.06,144.67ZM69.9,161.48c0,.26,0,.51,0,.77l-8.32.43a.92.92,0,0,1-1-.93v-.53a.94.94,0,0,1,1-.94l8.32.43C69.88,161,69.9,161.22,69.9,161.48Zm-27.67,0c0-.26,0-.51,0-.77l8.32-.43a.94.94,0,0,1,1,.94v.53a.92.92,0,0,1-1,.93l-8.32-.43C42.25,162,42.23,161.74,42.23,161.48Zm11.41,2a.78.78,0,0,1,1.08-.17.77.77,0,0,1-.91,1.24A.78.78,0,0,1,53.64,163.51Zm-.73-2.25a.76.76,0,0,1-.49-1,.77.77,0,0,1,1-.49.76.76,0,0,1,.5,1A.77.77,0,0,1,52.91,161.26Zm1.85.22a1.31,1.31,0,1,1,1.3,1.31A1.31,1.31,0,0,1,54.76,161.48Zm.54-3.06a.77.77,0,1,1,.76.77A.77.77,0,0,1,55.3,158.42Zm2.11,4.92a.78.78,0,0,1,1.08.17.77.77,0,0,1-1.24.9A.77.77,0,0,1,57.41,163.34Zm.84-2.57a.76.76,0,0,1,.49-1,.77.77,0,0,1,1,.49.76.76,0,0,1-.49,1A.77.77,0,0,1,58.25,160.77Zm1.17-2.26-.38-.38a.94.94,0,0,1,.05-1.37l6.19-5.58a13.88,13.88,0,0,1,1.08,1.09l-5.57,6.19A1,1,0,0,1,59.42,158.51Zm-1.15-2.35-.75-8.44a13.77,13.77,0,0,1,7.24,3Zm-1-.16a.93.93,0,0,1-.93,1h-.54a.93.93,0,0,1-.92-1l.43-8.31c.25,0,.51,0,.76,0s.51,0,.77,0Zm-3.4.16-6.49-5.43a13.77,13.77,0,0,1,7.24-3Zm-.82.6a.94.94,0,0,1,0,1.37l-.38.38a.93.93,0,0,1-1.35,0l-5.58-6.19a13.88,13.88,0,0,1,1.08-1.09Zm-2.3,2.52-8.43.75a13.68,13.68,0,0,1,3-7.24Zm0,4.41-5.43,6.49a13.68,13.68,0,0,1-3-7.24Zm.61.82a.92.92,0,0,1,1.35,0l.38.38a.93.93,0,0,1,0,1.36l-6.19,5.58a13.76,13.76,0,0,1-1.08-1.08Zm2.51,2.29.75,8.44a13.69,13.69,0,0,1-7.24-3Zm1,.16a.93.93,0,0,1,.92-1h.54a.93.93,0,0,1,.93,1l-.43,8.32c-.26,0-.51,0-.77,0s-.51,0-.76,0Zm3.4-.16,6.49,5.43a13.77,13.77,0,0,1-7.24,3Zm.82-.6a.93.93,0,0,1-.05-1.36l.38-.38a.94.94,0,0,1,1.37,0l5.57,6.19a13.76,13.76,0,0,1-1.08,1.08Zm2.3-2.51,8.43-.75a13.68,13.68,0,0,1-3,7.24Zm0-4.41,5.43-6.49a13.68,13.68,0,0,1,3,7.24Z"
            />
          </svg>
        ),
      },
    },
    {
      name: "Minivan",
      icon: {
        rearIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 270 270"
            stroke="#FFF"
          >
            <defs></defs>
            <path
              class="van-1"
              d="M263.07,124.45v8.78a9.07,9.07,0,0,1-3,6.74V121.35A3.1,3.1,0,0,1,263.07,124.45Z"
            />
            <path
              fill="currentColor"
              d="M262.36,144.41l-1.7-2.54a3.38,3.38,0,0,1-.57-1.88V121.34a3.36,3.36,0,0,0-.27-1.33c-1.72-4-11.29-25.93-17.73-31.08l3-2.38a1.94,1.94,0,0,0-1.21-3.46l-120.2.16c-22.55,0-58.83,28.43-58.83,28.43s-49.94,5.5-57.25,19.72l-.17.35a7.4,7.4,0,0,0-.34.89,2,2,0,0,0-.12.34v19.38s1.77,8.88,8.87,11.37H257.65a5.29,5.29,0,0,0,5.28-5.29V146.3A3.44,3.44,0,0,0,262.36,144.41ZM91.2,103.46c13.52-7.93,25-12.29,32.44-12.29l24.82,0v27.2H91.2Z"
            />
            <path
              class="van-3"
              d="M148.46,91.14v27.2H69.15l.55-.43c.07-.06,11.63-8.32,21.5-14.45,13.52-7.93,25-12.29,32.44-12.29Z"
            />
            <polygon
              class="van-1"
              points="207.31 91.06 156.64 91.13 156.64 118.34 207.31 118.34 207.31 91.06"
            />
            <path
              class="van-1"
              d="M215,118.34h35.49c-4.68-10.32-10.49-21-13.3-23.23L232.05,91l-17.09,0Z"
            />
            <path
              class="van-1"
              d="M148.47,126.94a2,2,0,0,1-2,2H136.28a2,2,0,0,1-2-2,2,2,0,0,1,2-2h10.21A2,2,0,0,1,148.47,126.94Z"
            />
            <path
              class="van-1"
              d="M207.31,126.94a2,2,0,0,1-2,2H195.12a2,2,0,0,1-2-2,2,2,0,0,1,2-2h10.21A2,2,0,0,1,207.31,126.94Z"
            />
            <path
              class="van-1"
              d="M18.37,133.9V141A7.33,7.33,0,0,1,11,148.29H6.94V133a8.18,8.18,0,0,1,.62-1.59h8.32A2.5,2.5,0,0,1,18.37,133.9Z"
            />
            <path
              class="van-4"
              d="M233.24,169.63a23.29,23.29,0,1,1-16.47-28.51A23.29,23.29,0,0,1,233.24,169.63Z"
            />
            <polygon
              class="van-5"
              points="209.13 165.6 209.14 165.6 209.13 165.6 209.13 165.6"
            />
            <path
              class="van-5"
              d="M215.55,145.59a18.65,18.65,0,1,0,13.19,22.86A18.63,18.63,0,0,0,215.55,145.59Zm10,12.2-8,3.37a5.69,5.69,0,0,1-3.05-3l5.09-7.78A15.93,15.93,0,0,1,225.55,157.79Zm-10.49,3.43a.62.62,0,0,1-.77.44.63.63,0,0,1-.47-.77.67.67,0,0,1,.8-.44A.64.64,0,0,1,215.06,161.22Zm-3.75-13.52-.32,8.14h0c-1.7,2.82-5.22,2.5-5.24,2.5l-5.64-6.61A15.92,15.92,0,0,1,211.31,147.7Zm-1.42,12.12a.63.63,0,0,1-.77.47.67.67,0,0,1-.44-.8.64.64,0,0,1,.77-.44A.61.61,0,0,1,209.89,159.82Zm-14.56-.33c0-.17.11-.32.16-.49l7.55,2.54a5.94,5.94,0,0,1,.67,5.51l-.07.18-7.27,3.28A15.82,15.82,0,0,1,195.33,159.49ZM206.62,179a16,16,0,0,1-4.79-2.18l4.12-5.94h0c2.79-1.41,5.48.45,5.74.64l1.11,7.88A16,16,0,0,1,206.62,179Zm2.53-10.94a.64.64,0,0,1,1.24.33.64.64,0,0,1-.8.44A.62.62,0,0,1,209.15,168.06Zm17-.33a15.79,15.79,0,0,1-5.64,8.46l-4.6-5.67.05-.07a5.69,5.69,0,0,1,2.12-5.29l8.37,1.19Q226.31,167,226.13,167.73ZM213.26,164c0,.05,0,.09,0,.13h0s0,.08,0,.13h0a.06.06,0,0,1,0,0h0a1.85,1.85,0,0,1-.12.33,2.39,2.39,0,0,1-.56.83h0a3,3,0,0,1-.39.31h0a2.49,2.49,0,0,1-.43.22l6.37,11.76a15.32,15.32,0,0,1-2.47,1l-4.27-12.68h-.05a2.6,2.6,0,0,1-.56.06h-.05a2.08,2.08,0,0,1-.6-.09,2.27,2.27,0,0,1-.58-.23h0a2.71,2.71,0,0,1-.35-.24l.12.1-.13-.1L199.61,175a16.39,16.39,0,0,1-1.77-2l11-7.65c-.05-.06-.11-.1-.15-.16h0a2.69,2.69,0,0,1-.28-.46l0,0a3.71,3.71,0,0,1-.16-.47s0,0,0-.06a2.07,2.07,0,0,1-.06-.5s0,0,0-.06a2.76,2.76,0,0,1,.06-.57h0a.19.19,0,0,1,0-.08.67.67,0,0,1,.06-.15l-11.73-6.5a15.25,15.25,0,0,1,1.51-2.38l10.42,8.44a3.08,3.08,0,0,1,.37-.5s0,0,0,0a2.56,2.56,0,0,1,.66-.47l0,0a2.5,2.5,0,0,1,1.69-.16h0l3-13c.2,0,.39.07.58.12A15.06,15.06,0,0,1,217,149l-3.11,7.42v0l-2.06,5,.2.08h0a2.42,2.42,0,0,1,1,1.13v0a2.64,2.64,0,0,1,.22,1l1.7-.39,11.39-2.47a15.73,15.73,0,0,1,.28,2.75Zm1.91,2.6a.64.64,0,0,1-1.24-.33.66.66,0,0,1,.8-.47A.67.67,0,0,1,215.17,166.58Zm-9.68-2.36a.66.66,0,0,1,.8-.44A.64.64,0,0,1,206,165,.66.66,0,0,1,205.49,164.22Z"
            />
            <path
              class="van-5"
              d="M210,162.12h0a1.62,1.62,0,0,0-.43.31l0,0a1.52,1.52,0,0,0-.32.46h0a1.09,1.09,0,0,0-.1.25.09.09,0,0,0,0,.05h0a2.4,2.4,0,0,0,0,.37v0a1.12,1.12,0,0,0,0,.32v0a1.6,1.6,0,0,0,.1.31v0a1.49,1.49,0,0,0,.18.3h0a2.18,2.18,0,0,0,.21.22l.15.12-.12-.09a1.27,1.27,0,0,0,.27.18h0a1.71,1.71,0,0,0,.76.21h0a1.5,1.5,0,0,0,.37,0h0l.26-.07h0a1.59,1.59,0,0,0,.29-.15h0a1.69,1.69,0,0,0,.25-.2h0a1.58,1.58,0,0,0,.37-.54,1.31,1.31,0,0,0,.08-.21h0v0h0s0-.05,0-.08h0a1.76,1.76,0,0,0-.11-1h0a1.6,1.6,0,0,0-.68-.73h0a2.38,2.38,0,0,0-.4-.16h0A1.57,1.57,0,0,0,210,162.12Z"
            />
            <path
              class="van-4"
              d="M76,169.63A23.29,23.29,0,1,1,59.5,141.12,23.28,23.28,0,0,1,76,169.63Z"
            />
            <path
              class="van-5"
              d="M58.28,145.59a18.65,18.65,0,1,0,13.19,22.86A18.63,18.63,0,0,0,58.28,145.59Zm10,12.2-8,3.37a5.65,5.65,0,0,1-3-3l5.09-7.78A15.93,15.93,0,0,1,68.28,157.79Zm-10.49,3.43a.62.62,0,0,1-.77.44.63.63,0,0,1-.47-.77.66.66,0,0,1,.79-.44A.63.63,0,0,1,57.79,161.22ZM54,147.7l-.32,8.14h0c-1.69,2.82-5.21,2.5-5.23,2.5l-5.64-6.61A15.92,15.92,0,0,1,54,147.7Zm-1.42,12.12a.63.63,0,0,1-.77.47.67.67,0,0,1-.44-.8.64.64,0,0,1,.77-.44A.61.61,0,0,1,52.62,159.82Zm-14.56-.33c0-.17.1-.32.15-.49l7.56,2.54a5.92,5.92,0,0,1,.66,5.51l-.06.18-7.27,3.28A15.82,15.82,0,0,1,38.06,159.49ZM49.35,179a16,16,0,0,1-4.79-2.18l4.12-5.94h0c2.8-1.41,5.49.45,5.75.64l1.11,7.88A16,16,0,0,1,49.35,179Zm2.53-10.94a.64.64,0,0,1,.77-.44.62.62,0,0,1,.46.77.64.64,0,0,1-.79.44A.62.62,0,0,1,51.88,168.06Zm17-.33a15.79,15.79,0,0,1-5.64,8.46l-4.6-5.67,0-.07a5.72,5.72,0,0,1,2.13-5.29l8.36,1.19C69.07,166.81,69,167.27,68.86,167.73ZM56,164c0,.05,0,.09,0,.13h0a.79.79,0,0,1,0,.13h0a.06.06,0,0,0,0,0h0a3.21,3.21,0,0,1-.12.33,2.53,2.53,0,0,1-.56.83h0a3,3,0,0,1-.4.31h0a2.49,2.49,0,0,1-.43.22l6.37,11.76a15.7,15.7,0,0,1-2.47,1l-4.27-12.68H54a2.6,2.6,0,0,1-.56.06h-.06a2.06,2.06,0,0,1-.59-.09,2.27,2.27,0,0,1-.58-.23h0a2.16,2.16,0,0,1-.35-.24,1.27,1.27,0,0,0,.12.1l-.13-.1L42.34,175a15.6,15.6,0,0,1-1.77-2l11-7.65c-.05-.06-.11-.1-.15-.16h0a2.69,2.69,0,0,1-.28-.46l0,0a2.6,2.6,0,0,1-.16-.47s0,0,0-.06a4,4,0,0,1-.06-.5v-.06A2.76,2.76,0,0,1,51,163h0s0-.06,0-.08a.67.67,0,0,1,.06-.15l-11.73-6.5a15.25,15.25,0,0,1,1.51-2.38l10.42,8.44a3.08,3.08,0,0,1,.37-.5l0,0a2.56,2.56,0,0,1,.66-.47l0,0a2.48,2.48,0,0,1,1.68-.16h0l3-13c.19,0,.39.07.58.12a15.51,15.51,0,0,1,2.14.76l-3.11,7.42,0,0-2.05,5,.19.08h0a2.49,2.49,0,0,1,1,1.13v0a2.63,2.63,0,0,1,.21,1l1.7-.39,11.4-2.47a15.73,15.73,0,0,1,.28,2.75Zm1.9,2.6a.64.64,0,0,1-1.23-.33.64.64,0,1,1,1.23.33Zm-9.67-2.36a.66.66,0,0,1,.8-.44.64.64,0,0,1-.33,1.23A.66.66,0,0,1,48.22,164.22Z"
            />
            <path
              class="van-5"
              d="M52.75,162.12h0a1.48,1.48,0,0,0-.43.31l0,0a1.69,1.69,0,0,0-.31.46h0a1.89,1.89,0,0,0-.11.25v.05h0a2.4,2.4,0,0,0,0,.37v0a1.12,1.12,0,0,0,0,.32v0a1.6,1.6,0,0,0,.1.31v0a1.49,1.49,0,0,0,.18.3h0a2.09,2.09,0,0,0,.2.22l.16.12-.12-.09a1.27,1.27,0,0,0,.27.18h0a1.2,1.2,0,0,0,.37.15,1.32,1.32,0,0,0,.39.06h0a1.55,1.55,0,0,0,.37,0h0l.25-.07h0a1.32,1.32,0,0,0,.29-.15h0a2.43,2.43,0,0,0,.25-.2h0a1.58,1.58,0,0,0,.37-.54,1.29,1.29,0,0,0,.07-.21h0a0,0,0,0,0,0,0h0a.22.22,0,0,0,0-.08h0a1.61,1.61,0,0,0-.11-1h0a1.6,1.6,0,0,0-.68-.73h0a2.5,2.5,0,0,0-.41-.16h0A1.57,1.57,0,0,0,52.75,162.12Z"
            />
          </svg>
        ),
        fullIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 270 270"
          >
            <defs></defs>
            <path
              class="van-1"
              d="M263.07,124.45v8.78a9.07,9.07,0,0,1-3,6.74V121.35A3.1,3.1,0,0,1,263.07,124.45Z"
            />
            <path
              fill="currentColor"
              stroke="#fff"
              d="M262.36,144.41l-1.7-2.54a3.38,3.38,0,0,1-.57-1.88V121.34a3.36,3.36,0,0,0-.27-1.33c-1.72-4-11.29-25.93-17.73-31.08l3-2.38a1.94,1.94,0,0,0-1.21-3.46l-120.2.16c-22.55,0-58.83,28.43-58.83,28.43s-49.94,5.5-57.25,19.72l-.17.35a7.4,7.4,0,0,0-.34.89,2,2,0,0,0-.12.34v19.38s1.77,8.88,8.87,11.37H257.65a5.29,5.29,0,0,0,5.28-5.29V146.3A3.44,3.44,0,0,0,262.36,144.41ZM91.2,103.46c13.52-7.93,25-12.29,32.44-12.29l24.82,0v27.2H91.2Z"
            />
            <path
              class="van-1"
              d="M148.46,91.14v27.2H69.15l.55-.43c.07-.06,11.63-8.32,21.5-14.45,13.52-7.93,25-12.29,32.44-12.29Z"
            />
            <polygon
              class="van-1"
              points="207.31 91.06 156.64 91.13 156.64 118.34 207.31 118.34 207.31 91.06"
            />
            <path
              class="van-1"
              d="M215,118.34h35.49c-4.68-10.32-10.49-21-13.3-23.23L232.05,91l-17.09,0Z"
            />
            <path
              class="van-1"
              d="M148.47,126.94a2,2,0,0,1-2,2H136.28a2,2,0,0,1-2-2,2,2,0,0,1,2-2h10.21A2,2,0,0,1,148.47,126.94Z"
            />
            <path
              class="van-1"
              d="M207.31,126.94a2,2,0,0,1-2,2H195.12a2,2,0,0,1-2-2,2,2,0,0,1,2-2h10.21A2,2,0,0,1,207.31,126.94Z"
            />
            <path
              class="van-1"
              d="M18.37,133.9V141A7.33,7.33,0,0,1,11,148.29H6.94V133a7.85,7.85,0,0,1,.62-1.58h8.32A2.49,2.49,0,0,1,18.37,133.9Z"
            />
            <path
              class="van-4"
              d="M233.24,169.64a23.29,23.29,0,1,1-16.47-28.52A23.3,23.3,0,0,1,233.24,169.64Z"
            />
            <polygon
              class="van-4"
              points="209.13 165.6 209.14 165.6 209.13 165.6 209.13 165.6"
            />
            <path
              class="van-4"
              d="M215.55,145.59a18.66,18.66,0,1,0,13.19,22.86A18.63,18.63,0,0,0,215.55,145.59Zm10,12.2-8,3.37a5.69,5.69,0,0,1-3.05-3l5.09-7.78A15.93,15.93,0,0,1,225.55,157.79Zm-10.49,3.43a.62.62,0,0,1-.77.44.63.63,0,0,1-.47-.77.67.67,0,0,1,.8-.44A.64.64,0,0,1,215.06,161.22Zm-3.75-13.52-.32,8.14h0c-1.7,2.82-5.22,2.5-5.24,2.5l-5.64-6.61A15.92,15.92,0,0,1,211.31,147.7Zm-1.42,12.12a.63.63,0,0,1-.77.47.67.67,0,0,1-.44-.8.64.64,0,0,1,.77-.44A.61.61,0,0,1,209.89,159.82Zm-14.56-.33c0-.17.11-.32.16-.49l7.55,2.54a5.94,5.94,0,0,1,.67,5.51l-.07.18-7.27,3.28A15.81,15.81,0,0,1,195.33,159.49ZM206.62,179a16,16,0,0,1-4.79-2.18l4.12-5.93h0c2.79-1.41,5.48.45,5.74.64l1.11,7.88A16,16,0,0,1,206.62,179Zm2.53-10.94a.64.64,0,0,1,1.24.33.64.64,0,0,1-.8.44A.62.62,0,0,1,209.15,168.06Zm17-.33a15.79,15.79,0,0,1-5.64,8.46l-4.6-5.67.05-.07a5.71,5.71,0,0,1,2.12-5.29l8.37,1.19Q226.31,167,226.13,167.73ZM213.26,164s0,.08,0,.12h0s0,.09,0,.13h0a.06.06,0,0,1,0,0h0a1.85,1.85,0,0,1-.12.33,2.39,2.39,0,0,1-.56.83h0a3,3,0,0,1-.39.31h0a2.49,2.49,0,0,1-.43.22l6.37,11.76a16.32,16.32,0,0,1-2.47,1l-4.27-12.69h-.05a2.6,2.6,0,0,1-.56.06h-.05a2.47,2.47,0,0,1-.6-.08,2.72,2.72,0,0,1-.58-.24h0a2.71,2.71,0,0,1-.35-.24l.12.1-.13-.1L199.61,175a17,17,0,0,1-1.77-2l11-7.66a1.14,1.14,0,0,1-.15-.16h0a2.69,2.69,0,0,1-.28-.46l0,0a3.38,3.38,0,0,1-.16-.48s0,0,0-.06a2.07,2.07,0,0,1-.06-.5s0,0,0-.06a2.76,2.76,0,0,1,.06-.57h0a.25.25,0,0,1,0-.08.94.94,0,0,1,.06-.15l-11.73-6.5a15.73,15.73,0,0,1,1.51-2.38l10.42,8.44a3.08,3.08,0,0,1,.37-.5s0,0,0,0a2.56,2.56,0,0,1,.66-.47l0,0a2.5,2.5,0,0,1,1.69-.17h0l3-13c.2.05.39.07.58.13A15,15,0,0,1,217,149l-3.11,7.42v0l-2.06,5c.06,0,.13,0,.2.08h0a2.4,2.4,0,0,1,1,1.12v0a2.64,2.64,0,0,1,.22,1l1.7-.39,11.39-2.47a15.73,15.73,0,0,1,.28,2.75Zm1.91,2.59a.64.64,0,0,1-.77.44.63.63,0,0,1-.47-.77.66.66,0,0,1,.8-.47A.67.67,0,0,1,215.17,166.58Zm-9.68-2.36a.66.66,0,0,1,.8-.44.64.64,0,0,1,.44.77.62.62,0,0,1-.77.46A.66.66,0,0,1,205.49,164.22Z"
            />
            <path
              class="van-4"
              d="M210,162.12h0a1.88,1.88,0,0,0-.43.31l0,0a1.6,1.6,0,0,0-.32.45h0a1.09,1.09,0,0,0-.1.25.13.13,0,0,0,0,.06h0a2.19,2.19,0,0,0,0,.36v0a1.12,1.12,0,0,0,0,.32v0a1.6,1.6,0,0,0,.1.31v0a1.49,1.49,0,0,0,.18.3h0a2.18,2.18,0,0,0,.21.22l.15.12-.12-.09a1.27,1.27,0,0,0,.27.18h0a1.42,1.42,0,0,0,.76.21h0a1.5,1.5,0,0,0,.37,0h0l.26-.07h0a1.59,1.59,0,0,0,.29-.15h0a1.22,1.22,0,0,0,.25-.2h0a1.58,1.58,0,0,0,.37-.54,1.31,1.31,0,0,0,.08-.21h0v0h0s0-.05,0-.08h0a1.76,1.76,0,0,0-.11-1h0a1.6,1.6,0,0,0-.68-.73h0a2.38,2.38,0,0,0-.4-.16h0A1.62,1.62,0,0,0,210,162.12Z"
            />
            <path
              class="van-4"
              d="M76,169.64A23.29,23.29,0,1,1,59.5,141.12,23.28,23.28,0,0,1,76,169.64Z"
            />
            <path
              class="van-4"
              d="M58.28,145.59a18.66,18.66,0,1,0,13.19,22.86A18.63,18.63,0,0,0,58.28,145.59Zm10,12.2-8,3.37a5.65,5.65,0,0,1-3-3l5.09-7.78A15.93,15.93,0,0,1,68.28,157.79Zm-10.49,3.43a.62.62,0,0,1-.77.44.63.63,0,0,1-.47-.77.66.66,0,0,1,.79-.44A.64.64,0,0,1,57.79,161.22ZM54,147.7l-.32,8.14h0c-1.69,2.82-5.21,2.5-5.23,2.5l-5.64-6.61A15.92,15.92,0,0,1,54,147.7Zm-1.42,12.12a.63.63,0,0,1-.77.47.67.67,0,0,1-.44-.8.64.64,0,0,1,.77-.44A.61.61,0,0,1,52.62,159.82Zm-14.56-.33c0-.17.1-.32.15-.49l7.56,2.54a5.92,5.92,0,0,1,.66,5.51l-.06.18-7.27,3.28A15.81,15.81,0,0,1,38.06,159.49ZM49.35,179a16,16,0,0,1-4.79-2.18l4.12-5.93h0c2.8-1.41,5.49.45,5.75.64l1.11,7.88A16,16,0,0,1,49.35,179Zm2.53-10.94a.64.64,0,0,1,.77-.44.63.63,0,0,1,.46.77.64.64,0,0,1-.79.44A.62.62,0,0,1,51.88,168.06Zm17-.33a15.79,15.79,0,0,1-5.64,8.46l-4.6-5.67,0-.07a5.73,5.73,0,0,1,2.13-5.29l8.36,1.19C69.07,166.81,69,167.27,68.86,167.73ZM56,164s0,.08,0,.12h0a.61.61,0,0,1,0,.13h0a.06.06,0,0,0,0,0h0a3.21,3.21,0,0,1-.12.33,2.53,2.53,0,0,1-.56.83h0a3,3,0,0,1-.4.31h0a2.49,2.49,0,0,1-.43.22l6.37,11.76a16.76,16.76,0,0,1-2.47,1l-4.27-12.69H54a2.6,2.6,0,0,1-.56.06h-.06a2.46,2.46,0,0,1-.59-.08,2.72,2.72,0,0,1-.58-.24h0a2.16,2.16,0,0,1-.35-.24,1.27,1.27,0,0,0,.12.1l-.13-.1L42.34,175a16.19,16.19,0,0,1-1.77-2l11-7.66a1.14,1.14,0,0,1-.15-.16h0a2.69,2.69,0,0,1-.28-.46l0,0a2.46,2.46,0,0,1-.16-.48s0,0,0-.06a4,4,0,0,1-.06-.5v-.06A2.76,2.76,0,0,1,51,163h0s0,0,0-.08a.94.94,0,0,1,.06-.15l-11.73-6.5a15.73,15.73,0,0,1,1.51-2.38l10.42,8.44a3.08,3.08,0,0,1,.37-.5l0,0a2.56,2.56,0,0,1,.66-.47l0,0a2.48,2.48,0,0,1,1.68-.17h0l3-13c.19.05.39.07.58.13a15.47,15.47,0,0,1,2.14.75l-3.11,7.42,0,0-2.05,5a1.54,1.54,0,0,1,.19.08h0a2.47,2.47,0,0,1,1,1.12v0a2.63,2.63,0,0,1,.21,1l1.7-.39,11.4-2.47a15.73,15.73,0,0,1,.28,2.75Zm1.9,2.59a.64.64,0,0,1-1.23-.33.64.64,0,1,1,1.23.33Zm-9.67-2.36a.66.66,0,0,1,.8-.44.64.64,0,0,1,.44.77.62.62,0,0,1-.77.46A.66.66,0,0,1,48.22,164.22Z"
            />
            <path
              class="van-4"
              d="M52.75,162.12h0a1.69,1.69,0,0,0-.43.31l0,0a1.8,1.8,0,0,0-.31.45h0a1.89,1.89,0,0,0-.11.25v.06h0a2.19,2.19,0,0,0,0,.36v0a1.12,1.12,0,0,0,0,.32v0a1.6,1.6,0,0,0,.1.31v0a1.49,1.49,0,0,0,.18.3h0a2.09,2.09,0,0,0,.2.22l.16.12-.12-.09a1.27,1.27,0,0,0,.27.18h0a1.53,1.53,0,0,0,.37.16,1.74,1.74,0,0,0,.39,0h0a1.55,1.55,0,0,0,.37,0h0l.25-.07h0a1.32,1.32,0,0,0,.29-.15h0a1.57,1.57,0,0,0,.25-.2h0a1.58,1.58,0,0,0,.37-.54,1.29,1.29,0,0,0,.07-.21h0a0,0,0,0,0,0,0h0a.19.19,0,0,0,0-.08h0a1.61,1.61,0,0,0-.11-1h0a1.6,1.6,0,0,0-.68-.73h0a2.5,2.5,0,0,0-.41-.16h0A1.62,1.62,0,0,0,52.75,162.12Z"
            />
          </svg>
        ),
      },
    },
    {
      name: "Rear Windshield",
      icon: {
        fullIcon: (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 46.38">
            <defs></defs>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  fill="currentColor"
                  d="M62.13,21.4c0-5.39-3.85-15.7-5.93-19.27A4.29,4.29,0,0,0,52.48,0h-41A4.32,4.32,0,0,0,7.79,2.13C5.71,5.7,1.87,16,1.87,21.4Z"
                />
                <path
                  class="rw-2"
                  d="M5.6,18.13c.78-3.12,2.51-10.07,5-14.36a1,1,0,0,1,.91-.51h41a1,1,0,0,1,.91.51c2.5,4.29,4.23,11.24,5,14.36Z"
                />
                <path
                  class="rw-2"
                  d="M49.89,40V44.3A2.08,2.08,0,0,0,52,46.38h7.85A2.08,2.08,0,0,0,61.9,44.3v-8Z"
                />
                <path
                  class="rw-2"
                  d="M2.11,36.34v8a2.07,2.07,0,0,0,2.08,2.08H12a2.08,2.08,0,0,0,2.07-2.08V40Z"
                />
                <path
                  fill="currentColor"
                  d="M62.94,32.14a2.37,2.37,0,0,1-.81.55,4.64,4.64,0,0,1,.2.54C63,35.36,64.56,42,51.94,42H12.06C-.56,42,1,35.36,1.67,33.23a4.64,4.64,0,0,1,.2-.54,2.37,2.37,0,0,1-.81-.55c-2-2-.49-5.51-.49-5.51.57-1.47.31-1.21.31-1.21-1.1-2.93,1-4,1-4H62.13s2.09,1.09,1,4c0,0-.27-.26.31,1.21C63.43,26.63,65,30.12,62.94,32.14Z"
                />
                <path
                  class="rw-3"
                  d="M62.13,32.69a4.64,4.64,0,0,1,.2.54H1.67a4.64,4.64,0,0,1,.2-.54,2.37,2.37,0,0,1-.81-.55H62.94A2.37,2.37,0,0,1,62.13,32.69Z"
                />
                <path
                  class="rw-2"
                  d="M5.6,25.17v7H1.06c-2-2-.49-5.51-.49-5.51.57-1.47.31-1.21.31-1.21-1.1-2.93,1-4,1-4A4.4,4.4,0,0,1,5.6,25.17Z"
                />
                <path
                  class="rw-2"
                  d="M58.4,25.17v7h4.54c2-2,.49-5.51.49-5.51-.57-1.47-.31-1.21-.31-1.21,1.1-2.93-1-4-1-4A4.4,4.4,0,0,0,58.4,25.17Z"
                />
                <rect x="21.15" y="23.47" width="21.7" height="6.6" rx="2.25" />
              </g>
            </g>
          </svg>
        ),
      },
    },
    {
      name: "Front Windshield",
      icon: {
        fullIcon: (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 43.09">
            <defs></defs>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  class="fw-1"
                  d="M56.66,13.41l-1.47,2.06v1.76H62a2,2,0,0,0,2-2v-.64a2,2,0,0,0-2-2h-3.7A2,2,0,0,0,56.66,13.41Z"
                />
                <path
                  class="fw-1"
                  d="M7.34,13.41l1.47,2.06v1.76H2a2,2,0,0,1-2-2v-.64a2,2,0,0,1,2-2h3.7A2,2,0,0,1,7.34,13.41Z"
                />
                <path
                  fill="currentColor"
                  d="M61.67,24V35.87a2,2,0,0,1-2,2H4.33a2,2,0,0,1-2-2V24c0-4.3,6.35-8.48,6.35-8.48L12.25,3.33A3,3,0,0,1,14.4,1.26C21-.42,43-.42,49.6,1.26a3,3,0,0,1,2.15,2.07l3.57,12.14S61.67,19.65,61.67,24Z"
                />
                <path
                  class="fw-1"
                  d="M52.06,16.27l-3.53-12C45.81,3.59,39.32,3.14,32,3.14s-13.8.45-16.53,1.14L12,16.19l0,.08Z"
                />
                <path
                  class="fw-1"
                  d="M5.35,37.87v3.21a2,2,0,0,0,2,2h7.76a2,2,0,0,0,2-2V37.87Z"
                />
                <path
                  class="fw-1"
                  d="M46.77,37.87v3.21a2,2,0,0,0,2,2h7.76a2,2,0,0,0,2-2V37.87Z"
                />
                <path
                  class="fw-1"
                  d="M11.39,23.66a4.09,4.09,0,1,0,4.09,4.08A4.09,4.09,0,0,0,11.39,23.66Z"
                />
                <path
                  class="fw-1"
                  d="M52.61,23.66a4.09,4.09,0,1,0,4.09,4.08A4.09,4.09,0,0,0,52.61,23.66Z"
                />
                <rect
                  class="fw-1"
                  x="22.53"
                  y="25.89"
                  width="18.94"
                  height="3.66"
                  rx="1.83"
                />
              </g>
            </g>
          </svg>
        ),
      },
    },
  ];

  return { schedule };
};

export default getHours;
